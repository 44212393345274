import { createEndpoint } from 'new/api/utils';
import { api } from 'new/api/axios';
import {
  AddPaymentMethod,
  BillingListItem,
  PaymentMethodResponse,
  RemovePaymentMethod,
} from 'new/modules/MultiLocationBilling/types';
import { AssignPaymentMethodLocationsRequest } from 'new/modules/BillingPage/types';

export const getBillingList = createEndpoint<BillingListItem[], unknown>({
  url: '/multi-location/billing/:id',
  endpoint: ({ url }) => api.get<BillingListItem[]>(url),
});

export const getPaymentMethods = createEndpoint<
  PaymentMethodResponse,
  { dentistInfoId: number }
>({
  url: '/dentists/getOrgTierBillingMethod',
  endpoint: ({ url, params }) => api.post<PaymentMethodResponse>(url, params),
});

export const assignPaymentMethodToLocations = createEndpoint<
  unknown,
  AssignPaymentMethodLocationsRequest
>({
  url: 'dentists/setDefaultTierPaymentMethod',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const addPaymentMethod = createEndpoint<unknown, AddPaymentMethod>({
  url: '/dentists/addTierPaymentMethod/org',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const removeOrgPaymentMethod = createEndpoint<
  unknown,
  RemovePaymentMethod
>({
  url: '/dentists/removeTierPaymentMethod',
  endpoint: ({ url, params }) => api.post(url, params),
});
