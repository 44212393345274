import create, { GetState, SetState } from 'zustand';
import { persist, StoreApiWithPersist } from 'zustand/middleware';

type SidebarStore = {
  isExpanded: boolean;
  toggleIsExpanded: () => void;
  viewAsStandard: boolean;
  tempViewAsMulti: boolean;
  setViewAsStandard: (viewAsStandard: boolean) => void;
  setTempViewAsMulti: (tempViewAsMulti: boolean) => void;
};

export const useSidebarStore = create<
  SidebarStore,
  SetState<SidebarStore>,
  GetState<SidebarStore>,
  StoreApiWithPersist<SidebarStore>
>(
  persist(
    (set) => ({
      isExpanded: true as boolean,
      toggleIsExpanded: () =>
        set((state) => ({ isExpanded: !state.isExpanded })),
      viewAsStandard: false as boolean,
      tempViewAsMulti: false as boolean,
      setViewAsStandard: (viewAsStandard) =>
        set((state) => ({
          ...state,
          viewAsStandard: viewAsStandard as boolean,
        })),
      setTempViewAsMulti: (tempViewAsMulti) =>
        set((state) => ({
          ...state,
          tempViewAsMulti: tempViewAsMulti as boolean,
        })),
    }),
    { name: 'sidebar', version: 1, getStorage: () => sessionStorage },
  ),
);
