import { NamedRoute } from 'new/routing/types';

export type ManagePlansRouteName = 'ManagePlans';

const MANAGE_PLANS_BASE_URL = `/account/manage-plans`;

export const managePlansRoutes: NamedRoute[] = [
  {
    name: 'ManagePlans',
    path: MANAGE_PLANS_BASE_URL,
    getPath: (id) => `${MANAGE_PLANS_BASE_URL}${id ? `?id=${id}` : ''}`,
    getManageLocationPath: (isGroupId) => `${MANAGE_PLANS_BASE_URL}${isGroupId ? `?filterById=${isGroupId}` : ''}`,
    getManageLocationByTemplatePath: (id, loca) =>`${MANAGE_PLANS_BASE_URL}${id ? `?id=${-2}&groupId=${id}` : ''}`,
    getManageLocationByTemplatePathByLocation: (id:any) =>`${MANAGE_PLANS_BASE_URL}${id ? `?id=${-2}&locationId=${id}` : ''}`
  },
];
