import { NamedRoute } from '../../routing/types';

export type EnrollRouteName = 'EnrollPage';

export const enrollRoutes: NamedRoute[] = [
  {
    name: 'EnrollPage',
    path: '/office-enroll',
    permission: '_all',
  },
];
