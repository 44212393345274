import { TENANT } from "new/tenants";

const dhq = [
  {
    name: 'Adult Wellness Plan',
    description:
      '2 adult cleanings per year\n' +
      'Regular exam with cleaning\n' +
      'Oral cancer screening with cleaning\n' +
      '1 set of routine X-rays per year\n' +
      '1 emergency exam with X-ray per year\n' +
      'Up to __% off additional treatment\n' +
      'Most HSA and FSA cards accepted! \n' +
      'Total plan valued at over $___ \n',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'general_adult',
    recommendedPricingFormula: [
      ['1110', 2],
      ['0120', 2],
      ['0274', 1],
      ['0220', 2],
    ], // (D1110 x 2) + (D0120 x 2) + D0274 + (D0220 x 2)
  },
  {
    name: 'Child Wellness Plan',
    description:
      '2 child cleanings per year \n' +
      'Fluoride treatment with cleaning\n' +
      'Regular exam with cleaning\n' +
      'Oral cancer screening with cleaning\n' +
      '1 set of routine X-rays per year\n' +
      '1 emergency exam with X-ray per year\n' +
      'Up to __% off additional treatment\n' +
      'Most HSA and FSA cards accepted! \n' +
      'Total plan valued at over $___ \n',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: true,
    whitening: false,
    teledentistry: false,
    maxAge: 13,
    minAge: null,
    has_limit: true,
    ageLimit: null,
    limit_type: 'under',
    age_limit: 'under',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'general_child',
    recommendedPricingFormula: [
      ['1120', 2],
      ['0120', 2],
      ['0272', 1],
    ], // (D1120 x 2) + (D0120 x 2) + D0272
  },
  {
    name: 'Perio Maintenance Plan',
    description:
      '4 periodontal maintenance cleanings per year \n' +
      '2 regular exams per year\n' +
      'Oral cancer screening with cleaning\n' +
      '1 set of routine X-rays per year\n' +
      '1 emergency exam with X-ray per year\n' +
      'Up to __% off additional treatment\n' +
      'Most HSA and FSA cards accepted! \n' +
      'Total plan valued at over $___ \n',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: '',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'general_perio',
    recommendedPricingFormula: [
      ['4910', 4],
      ['0120', 2],
      ['0274', 1],
      ['0220', 2],
    ], // (D4910 x 4) + (D0120 x 2) + D0274 + (D0220 x 2)
  },
  {
    name: 'Toddler Plan',
    description:
      '1 routine cleaning every 6 months\n' +
      'Fluoride treatment with cleaning\n' +
      'Regular exam with cleaning\n' +
      '1 set of routine X-rays per year\n' +
      '1 emergency exam with X-ray per year\n' +
      'Up to __% off additional treatment\n',
    yearly_membership: [],
    subscription_age_group: 'child',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: 3,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'under',
    age_limit: 'under_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'pediatric_toddler',
    recommendedPricingFormula: [
      ['1120', 2],
      ['0145', 2],
      ['0272', 1],
    ], // (D1120 x 2) + (D0145 x 2) + D0272
  },
  {
    name: 'Child Plan',
    description:
      '1 routine cleaning every 6 months\n' +
      'Fluoride treatment with cleaning\n' +
      'Regular exam with cleaning\n' +
      'Oral cancer screening with cleaning\n' +
      '1 set of routine X-rays per year\n' +
      '1 emergency exam with X-ray per year\n' +
      'Up to __% off additional treatment\n',
    yearly_membership: [],
    subscription_age_group: 'child',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: 12,
    minAge: 4,
    has_limit: false,
    ageLimit: null,
    limit_type: 'between',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'pediatric_child',
    recommendedPricingFormula: [
      ['1120', 2],
      ['0120', 2],
      ['0272', 1],
    ], // (D1120 x 2) + (D0120 x 2) + D0272
  },
  {
    name: 'Teen Plan',
    description:
      '1 routine cleaning every 6 months\n' +
      'Fluoride treatment with cleaning\n' +
      'Regular exam with cleaning\n' +
      'Oral cancer screening with cleaning\n' +
      '1 set of routine X-rays per year\n' +
      '1 emergency exam with X-ray per year\n' +
      'Up to __% off additional treatment\n',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: 13,
    has_limit: false,
    ageLimit: null,
    limit_type: 'over',
    age_limit: 'over_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'pediatric_teen',
    recommendedPricingFormula: [
      ['1110', 2],
      ['0120', 2],
      ['0274', 1],
      ['0220', 2],
    ], // (D1110 x 2) + (D0120 x 2) + D0274 + (D0220 x 2)
  },
  {
    name: 'Perio 1x',
    description:
      '1 periodontal maintenance per year\n' +
      'Fluoride treatment with cleaning\n' +
      '1 regular exam per year\n' +
      'Oral cancer screening with cleaning\n' +
      '1 set of routine X-rays per year\n' +
      '1 emergency exam with X-ray per year\n' +
      'Up to __% off additional treatment\n',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: '',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'periodontic_perio1',
    recommendedPricingFormula: [
      ['4910', 1],
      ['0120', 1],
      ['0274', 1],
      ['0220', 2],
    ], //  D4910 + D0120 + D0274 + (D0220 x 2)
  },
  {
    name: 'Perio 2x',
    description:
      '1 periodontal maintenance every 6 months\n' +
      'Fluoride treatment with cleaning\n' +
      '2 regular exams per year\n' +
      'Oral cancer screening with cleaning\n' +
      '1 set of routine X-rays per year\n' +
      '1 emergency exam with X-ray per year\n' +
      'Up to __% off additional treatment\n',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: '',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'periodontic_perio2',
    recommendedPricingFormula: [
      ['4910', 2],
      ['0120', 2],
      ['0274', 1],
      ['0220', 2],
    ], // (D4910 x 2) + (D0120 x 2) + D0274 + (D0220 x 2)
  },
  {
    name: 'Perio 4x',
    description:
      '1 periodontal maintenance every 3 months\n' +
      'Fluoride treatment with cleaning\n' +
      '2 regular exams per year\n' +
      'Oral cancer screening with cleaning\n' +
      '1 set of routine X-rays per year\n' +
      '1 emergency exam with X-ray per year\n' +
      'Up to __% off additional treatment\n',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: '',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'periodontic_perio4',
    recommendedPricingFormula: [
      ['4910', 4],
      ['0120', 2],
      ['0274', 1],
      ['0220', 2],
    ], // (D4910 x 4) + (D0120 x 2) + D0274 + (D0220 x 2)
  },
  {
    name: 'Whitening Plan',
    description:
      '1 set of custom whitening trays\n1 tube of whitening gel at each cleaning as needed\n1 sonicare toothbrush\n1 sonicare toothbrush replacement head at each cleaning\n1 Zoom whitening treatment/year\nTotal plan valued at over $___',
    codes: [],
    min_price: 19.99,
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: true,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: null,
  },
  {
    name: 'Retainer Club',
    description: '1 new retainer every 6 months',
    codes: [],
    min_price: 5,
    yearly_price: null,
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: false,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: null,
  },
  {
    name: 'Denture Plan',
    description:
      '1 minor repair or refit/year due to wear and tear\n1 exam/oral cancer screening/year\n1__% off any additional services',
    codes: [],
    price: null,
    min_price: 5,
    yearly_price: null,
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: false,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: null,
  },
  {
    name: 'OverDenture Plan',
    description:
      '1 minor repair or refit/year due to wear and tear\n1 exam/oral cancer screening/year\n1 set of attachment replacements/year\n1__% off any additional services',
    codes: [],
    price: null,
    min_price: 5,
    yearly_price: null,
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: false,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: null,
  },
  {
    name: 'Out Of Network Plan',
    description: 'Teledentistry\nUp to __% off additional treatment',
    codes: [],
    price: null,
    min_price: 5,
    yearly_price: null,
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: false,
    fluoride: false,
    whitening: false,
    teledentistry: true,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: null,
  },
  {
    name: 'VIP Plan',
    description:
      '1 basic cleaning every 6 months\nOral cancer screening with cleaning\n1-2 exams/year\nX-rays as determined necessary\n1 emergency exam with X-ray/year\nUp to __% off additional treatment',
    codes: [],
    min_price: 25,
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: null,
  },
  {
    name: 'Sealant Plan',
    description:
      'Includes placement of sealants on all premolars and molars\nRepair or replacement of sealants placed under plan for life of plan\nRepair or replacement completed during a preventive appointment',
      // \nCan be combined with Adult or Child Preventive Plan',
    codes: [],
    min_price: 10,
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: null,
  },
  {
    name: 'Preventative Care Plan',
    description:
      'Fluoride Treatments with each cleaning\nPrescription Fluoride toothpaste after each cleaning',
    codes: [],
    min_price: 10,
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: null,
  },
  {
    name: 'New Patient Voucher Plan',
    description:
      'Comprehensive Initial Exam\nFull Mouth Radiographic Survey\nUp to 50% Off Initial Cleaning Voucher\nUp to 50% Off Recall Exam & Cleaning Voucher\nUp to 15% Off All Other Dental Treatment Voucher',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: null,
    recommendedPricingFormula: undefined,
  },
  {
    name: 'Established Patient Voucher Plan',
    description:
      'Initial Exam & Cleaning\nUp to 50% Necessary X-Rays Voucher\nUp to 50% Off Recall Exam & Cleaning Voucher\nUp to 15% Off All Other Dental Treatment Voucher',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: null,
    recommendedPricingFormula: undefined,
  },
];

const vhq = [
  {
    name: 'Exam & Eyewear',
    description:
      'One (1) Comprehensive Eye Exam to check your prescription and eye health each year (includes Advanced Diagnostic Testing of the retina and macula)\n30% OFF Prescription Eyewear \n20% OFF Non-Prescription Sunglasses \nMedical visits not included; may be billed to medical insurance',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'general_exam',
    recommendedPricingFormula: [
      ['1110', 2],
      ['0120', 2],
      ['0274', 1],
      ['0220', 2],
    ], // (D1110 x 2) + (D0120 x 2) + D0274 + (D0220 x 2)
  },
  {
    name: 'Eyewear Only + Contacts',
    description: "Eyewear Only Plan For those with eye health benefits that can be billed to a dental or medical plan (includes refraction: measurement of prescription)\n10% OFF Annual Supply of Contact Lenses\n30% OFF Prescription Eyewear \n20% OFF Non-Prescription Sunglasses \nMedical visits not included; may be billed to medical insurance\n*Must have had eye health exam within the last 6 months if not performed by our office",
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: true,
    whitening: false,
    teledentistry: false,
    maxAge: 13,
    minAge: null,
    has_limit: true,
    ageLimit: null,
    limit_type: 'under',
    age_limit: 'under',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'general_refraction',
    recommendedPricingFormula: [
      ['1120', 2],
      ['0120', 2],
      ['0272', 1],
    ], // (D1120 x 2) + (D0120 x 2) + D0272
  },
  {
    name: 'Exam & Eyewear + Contacts',
    description:
    "One (1) Annual Comprehensive Eye Exam to check your prescription and eye health each year (includes Advanced Diagnostic Testing of the retina and macula)\n\nOne (1) Annual Contact Lens Examination (Soft Sphere/Toric/Multi-Focals and Spherical RGPs)\n10% OFF Annual Supply of Contact Lenses\n30% OFF Prescription Eyewear \n20% OFF Non-Prescription Sunglasses \nMedical visits not included; may be billed to medical insurance",
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: '',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'general_exam_eyewear',
    recommendedPricingFormula: [
      ['4910', 4],
      ['0120', 2],
      ['0274', 1],
      ['0220', 2],
    ], // (D4910 x 4) + (D0120 x 2) + D0274 + (D0220 x 2)
  },{
    name: 'Eyewear Only',
    description: "Eyewear Only Plan For those with eye health benefits that can be billed to a dental or medical plan (includes refraction: measurement of prescription)\n30% OFF Prescription Eyewear \n20% OFF Non-Prescription Sunglasses \nMedical visits not included; may be billed to medical insurance\n*Must have had eye health exam within the last 6 months if not performed by our office",
    codes: [

    ],
    price: null, 
    min_price:1,
    yearly_price:null,
    yearly_membership: [],
    subscription_age_group:'adult',
    type:'custom',
    emergency:true,
    fluoride:false,
    whitening:false,
    teledentistry:false,
    maxAge:null,
    minAge:null,
    age_limit: 'no_limit',
    limit_type: 'no_limit',
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual:true,
    yearly_active:true,
    monthly_active:true,
    presetType:'refraction_eyewear'
  },
];

const SeedPlansCustom = {
  dhq,
  vhq,
}

export default SeedPlansCustom[TENANT.id];
