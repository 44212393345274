import { NamedRoute } from '../../routing/types';

export type MembershipPlansManageRoutesName = 'MembershipPlansManage';
const MEMBERSHIP_MANAGE_PLANS_BASE_URL = `/account/settings/membership-plan`;
export const membershipPlansManageRoutes: NamedRoute[] = [
  {
    name: 'MembershipPlansManage',
    path: MEMBERSHIP_MANAGE_PLANS_BASE_URL,
    getPath: (id) => `${MEMBERSHIP_MANAGE_PLANS_BASE_URL}${id ? `?id=${id}` : ''}`,
    permission: '_all',
  },
];
