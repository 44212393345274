import React, { ReactNode } from 'react';
import { Stack } from '@mui/material';
import IconFontAwesome from '../IconFontAwesome';
import Button from '../Button/Button';
import {
  ModalContainer,
  MainMessageText,
  SecondaryMessageText,
  ErrorTitle,
  ModalContent,
  Title,
} from './styles';

export type ErrorConfigProps = {
  onClose: () => void;
  mainMessage?: ReactNode;
  errorDescription?: ReactNode;
  buttonStyle?: 'primary' | 'secondary';
  buttonLabel?: string;
  title?: string;
  isCancelModal? :boolean;
};

const ErrorModalContent: React.FC<ErrorConfigProps> = ({
  onClose,
  mainMessage,
  errorDescription,
  buttonStyle,
  buttonLabel,
  title,
  isCancelModal,
}) => (
  <ModalContainer>
    <ModalContent>
      {title && <Title>{title}</Title>}
      <IconFontAwesome name="faFaceFrownSlightReg" color="error" size="5x" />
      <ErrorTitle >
        {isCancelModal ? 
        <>WE'VE GOT GOOD NEWS... AND BAD NEWS.</>
              :
        <>
        uh-oh.
        <br /> Something went wrong.
        </>
        }

      </ErrorTitle>
      { errorDescription && <SecondaryMessageText>{errorDescription}</SecondaryMessageText>}
      <MainMessageText>{mainMessage}</MainMessageText>
    </ModalContent>
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="flex-end"
      spacing={5}
      sx={{
        width: '100%',
        height: 100,
        position: 'relative',
      }}
    >
      <Button
        sx={{ width: 'fit-content', mt: 2 }}
        onClick={onClose}
        variant={buttonStyle === 'secondary' ? 'outlined' : 'contained'}
        color={buttonStyle === 'secondary' ? 'secondary' : 'primary'}
      >
        {buttonLabel || 'GO BACK'}
      </Button>
    </Stack>
  </ModalContainer>
);

export default ErrorModalContent;
