import { NamedRoute } from 'new/routing/types';

export type OldRoutesName = 'GetStartedRoute';

export const oldRoutes: NamedRoute[] = [
  {
    name: 'GetStartedRoute',
    path: '/dentist-new/get-started',
  },
];
