import { api } from '../../api/axios';
import {
  EmailFormValues,
  PromoFormValues,
  CodeSubmitResponse,
  MembershipModalSubmitValues,
  UserFromTokenResponse,
  UserFromTokenRequest,
  PromoCodeResponse
} from './types';
import { createEndpoint } from '../../api/utils';

export const submitEmailAddress = createEndpoint<unknown, EmailFormValues>({
  url: '/dentist-users/generate_code',
  endpoint: ({ url, params }) => {
    return api.post<unknown>(url, params);
  },
});

export const validateCouponCode = createEndpoint<PromoCodeResponse, PromoFormValues>({
  url: '/stripe-connect/loadDiscount',
  endpoint: ({ url, params }) => {
    return api.post(url, params);
  },
});

export const submitConfirmationCode = createEndpoint<
  CodeSubmitResponse,
  MembershipModalSubmitValues
>({
  url: '/dentist-users/validate_code',
  endpoint: ({ url, params }) => {
    return api.post(url, params);
  },
});

export const getUserFromToken = createEndpoint<
  UserFromTokenResponse,
  UserFromTokenRequest
>({
  url: '/users/me/account',
  endpoint: ({ url, params }) => api.get(url, { params }),
});
