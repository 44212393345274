import React, { useEffect } from 'react';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const useGPlacesApi = () => {
  const loaded = React.useRef(false);
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  useEffect(() => {
    if (apiKey) {
      if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
          loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`,
            document.querySelector('head'),
            'google-maps',
          );
        }

        loaded.current = true;
      }
    } else {
      throw new Error('Missing Google API key');
    }
  }, []);
};

export default useGPlacesApi;
