import { NamedRoute } from 'new/routing/types';

export type ReportsRouteName = 'Reports';

export const reportsRoutes: NamedRoute[] = [
  {
    name: 'Reports',
    path: `/account/reports`,
  },
];
