import { Tenant } from 'new/types/tenants';
import pngLogo from 'new/assets/logos/DHQ-logo-standard-ltbkgrd_cropped.png';
import pngLogoSmall from 'new/assets/logos/DHQ-logo-small.png';
import pngLogoOnLight from 'new/assets/logos/DHQ-logo-DHQblue_cropped.png';
import { ReactComponent as SvgLogo } from 'new/assets/logos/DHQ-ondark-cropped.svg';
import { ReactComponent as SvgLogoSmall } from 'new/assets/logos/DHQ-logo-small-cropped.svg';
import { ReactComponent as SvgLogoOnLight } from 'new/assets/logos/DHQ-logo-DHQ blue-cropped.svg';
import favIcon from 'assets/favicon_dhq.ico';
import loginBg from 'new/assets/login_bg.png';
import {
  jobTitleOptions,
  launchChecklistItems,
  leadSourceOptions,
  locationInfoList2,
  pmsPlanOptions,
  scriptingPdf,
  templateOptions,
} from '../constants/const.dhq';
import cardFront from 'new/assets/cardFront.jpg';
import cardBack from 'new/assets/cardBack.jpg';

const dhq: Tenant = {
  id: 'dhq',
  name: 'DentalHQ',
  shortName: 'DHQ',
  nameSimple: 'Dental',
  supportEmail: 'support@dentalhq.com',
  successEmail: 'success@dentalhq.com',
  supportPhone: '(254) DHQ-HELP',
  infoEmail: 'info@dentalhq.com',
  professionalName: 'dentist',
  professionalNamePlural: 'dentists',
  profession: 'dentistry',
  ucrName: 'UCR',
  prodUrl: 'https://app.dentalhq.com',
  prodDomain: 'dentalhq.com',
  adminButtonColor: '#00a1df',
  specialUserIds: 561,
  planMinimum: 10,
  features: {
    setupBaa: true,
    gpp: true,
    pms: true,
    marketingOnlyLinks: false,
    businessConnect: true,
    inhouseFinancing: true,
    defaultSpecialty: null,
    upgradeOffice: true,
    seasonOfGivingWidget: true,
    feePerMember: true,
    leaderboardWidgetType: 'darkGreen',
    printMaterials: true,
    leadSource: true,
    upgradeFromOldPlatform: true,
    changePlanCoordinatorDoubleConfirm: false,
    csvSetup: false,
    payoutIssues: true,
    canDisableFamilyDiscounts: true,
    switchingBanner: true,
  },
  disabledWidgets: [],
  constants: {
    launchRewards: {
      prize30: 50,
      prize60: 100,
      prize90: 150,
    },
    seasonOfGivingPrize: '$500, $250, and $125',
    seasonOfGivingImage:
      'https://dentalhq-files.s3.amazonaws.com/DHQ+-+Member+Magic+Rewards-03.png',
    marketingTemplates:
      'https://dentalhq-files.s3.amazonaws.com/Email+%26+Text+Blasts+to+Patients+(Template)+.docx',
    ucrPrefix: 'd',
    login: {
      fontFamilyContainer: 'Epilogue',
      fontFamilyTitle: 'Termina',
    },
    externalPatientLandingBaseUrl: '/accounts/signup/my-dentist',
    pmsPlanOptions,
    launchChecklistItems,
    leadSourceOptions,
    templateOptions,
    jobTitleOptions,
    defaultMonthlyPrice: 49,
    defaultYearlyPrice: 499,
    defaultChildStartingAge: 12,
    files: {
      scriptingPdf,
      quickstartGuidePdf: '',
      memberSignaturePdf:
        'https://dentalhq-uploads.s3.amazonaws.com/DMP_In-office_Agreement_(generic).pdf',
      termsAndConditions:
        'https://dentalhq-uploads.s3.amazonaws.com/Dental+Membership+Plan+Member+-+Terms+%26+Conditions_July+2024.pdf',
      termsAndConditionsSpanish:
        'https://dentalhq-uploads.s3.amazonaws.com/Dental+Membership+Plan+-+Terms+%26+Conditions_July+2024_ES-MX.pdf',
      openEnrollmentTemplate:
        'https://dentalhq-files.s3.amazonaws.com/%5BDHQ%5D+Open+Enrollment+Email+Templates.docx',
    },
    onboardingCalendly:
      'https://calendly.com/tkennedy-dhq/one-on-one-for-q-a-s',
    associationLogo: {
      1: 'https://s3.amazonaws.com/market_materials/admin-partner-icon-NCDS.png',
      2: 'https://s3.amazonaws.com/market_materials/admin-partner-icon-ODA.png',
      3: 'https://s3.amazonaws.com/market_materials/admin-partner-icon-gargle.png',
      4: 'https://s3.amazonaws.com/market_materials/admin-partner-icon-elite.png',
      5: 'https://s3.amazonaws.com/market_materials/admin-partner-icon-ss.png',
    },
    locationInfoList2,
    s3Path: 'https://dentalman_uploads.s3.amazonaws.com',
    hqOrigin: 'dentalhq',
    secretaryOfStateUrl:
      'https://help.dentalhq.com/en/articles/50-locating-verifying-your-legal-business-name',
    successOnboardingCalendly:
      'https://calendly.com/dhq-client-success/dentalhq-success-onboarding?hide_gdpr_banner=1',
    elevioId: '60d607527f2a5',
    redirectToDashboardPath: '/dentist-new/members',
    quantityOptions: [
      { value: 250, label: '250', price: 79, higherPrice: 99 },
      { value: 500, label: '500', price: 99, higherPrice: 119 },
      { value: 1000, label: '1000', price: 149, higherPrice: 219 },
      { value: 1500, label: '1500', price: 199, higherPrice: 279 },
      { value: 2000, label: '2000', price: 249, higherPrice: 349 },
      { value: 5000, label: '5000', price: 399, higherPrice: 449 },
    ],
    planCountsMarketingMaterials: 4,
    marketingMaterialsType: 'card',
  },
  elevio: {
    cancellations: '108',
    trackPayments: '25',
    pricing: '107',
  },
  logos: {
    pngLogo,
    pngLogoSmall,
    pngLogoOnLight,
    SvgLogo,
    SvgLogoSmall,
    SvgLogoOnLight,
    favIcon,
  },
  calendlyDemoLink:
    'https://calendly.com/dentalhq/demo?utm_source=dashboard&utm_content=referral_page',
  enrollNowPartner:
    'https://app.dentalhq.com/enroll/practice?promo=133D23PARTNER',
  enrollNow: 'https://app.dentalhq.com/enroll/practice',
  assets: {
    loginBg,
    printMaterialsFront: cardFront,
    printMaterialsBack: cardBack,
  },
  theme: {
    modal: {
      fontFamily: 'Barlow',
    },
  },
  awsStorageUrl: 'https://market_materials.s3.amazonaws.com',
};

export default dhq;
