import {
  PresetType,
  SubmitCode,
  TemplateName,
} from 'new/modules/MembershipPlansManage/types';
import { SEED_PLANS } from './constants';
import { ManagePlan, ManagePlanDetail, PlanStatus, TabState } from './types';
import { TenantStruct } from 'new/types/util';
import { TENANT } from 'new/tenants';
import {
  CodesTypes,
  getUCRData,
  UCRCodesDHQ,
  UCRCodesIds,
  UCRCodesVHQ,
} from 'new/components/Form/InputUCR';

export const NEW_TAB_ID = 100000000;

const seedTemplatesPresetTypeConfigVhq: Partial<
  Record<TemplateName, PresetType>
> = {
  'Exam & Eyewear': 'general_exam',
  'Eyewear Only + Contacts': 'general_refraction',
  'Exam & Eyewear + Contacts': 'general_exam_eyewear',
  'Eyewear Only': 'refraction_eyewear',
};
const seedTemplatesPresetTypeConfigDhq: Partial<
  Record<TemplateName, PresetType>
> = {
  'Adult Wellness Plan': 'general_adult',
  'Child Wellness Plan': 'general_child',
  'Perio Maintenance Plan': 'general_perio',
  'Toddler Plan': 'pediatric_toddler',
  'Child Plan': 'pediatric_child',
  'Teen Plan': 'pediatric_teen',
  'Perio 1x': 'periodontic_perio1',
  'Perio 2x': 'periodontic_perio2',
  'Perio 4x': 'periodontic_perio4',
  'New Patient Voucher Plan': 'voucher_patient',
  'Established Patient Voucher Plan': 'voucher_patient_established',
};

const seedTemplates: TenantStruct<Partial<Record<TemplateName, PresetType>>> = {
  vhq: seedTemplatesPresetTypeConfigVhq,
  dhq: seedTemplatesPresetTypeConfigDhq,
};

const seedTemplatesPresetTypeConfig = seedTemplates[TENANT.id];

export const addNewPlan = (planTemplateName: string) => {
  const templateIndex = SEED_PLANS.findIndex(
    (plan) => plan.name === planTemplateName,
  );

  const isTemplateWithPresetType =
    planTemplateName &&
    Object.keys(seedTemplatesPresetTypeConfig).includes(planTemplateName);

  const newInitialValues: ManagePlanDetail = {
    id: NEW_TAB_ID,
    planName: 'New Plan',
    showOnBC: true,
    description: '',
    locations: [],
    memberCount: { annually: 0, monthly: 0 },
    pricing: { annually: '0', monthly: '0' },
    updatedBy: undefined,
    limitType: '',
    groups: [],
    presetType: isTemplateWithPresetType
      ? seedTemplatesPresetTypeConfig[planTemplateName as TemplateName] ?? null
      : null,
    type: 'custom',
    yearly_id: 0, //TODO is this ok?
    minAge: null,
    maxAge: null,
    notes: '',
    priceCodes: [],
    subscription_age_group: 'adult',
    showOnEnrollmentAnnual: true,
    showOnEnrollmentMonthly: true,

    isDeleted: false,
    updatedAt: '',
    hasActiveMembers: false,
    worryFree: { year: 0, month: 0 },
    programName: '',
    programDescription: '',
    dentistSpecialtyId: 0,
    familyDiscounts: {
      family_discount_two: 0,
      family_discount_three: 0,
      family_discount_four: 0,
    },
    recommendedPricing: [],
    viewStatus: 'draft',
    spanishName: '',
    spanishDescription: '',
    showOnInternalEnrollmentAnnual: true,
    showOnInternalEnrollmentMonthly: true,
    target: '',
    targetType: 'master',
    forInsured: false,
    forUninsured: true,
    activationFee: null,
    disableGpp: false,
    disableFamilyDiscounts: false,
  };

  if (templateIndex >= 0) {
    const seed = SEED_PLANS[templateIndex];
    newInitialValues.planName = seed.name;
    newInitialValues.description = seed.description;
    newInitialValues.type = seed.type;
    newInitialValues.showOnBC = seed.showOnBC;
    newInitialValues.limitType = seed.limit_type;
    newInitialValues.subscription_age_group = seed.subscription_age_group;
    newInitialValues.showOnEnrollmentAnnual = seed.showOnEnrollmentAnnual;
    newInitialValues.showOnEnrollmentMonthly = seed.showOnEnrollmentMonthly;
    newInitialValues.minAge = seed.minAge;
    newInitialValues.maxAge = seed.maxAge;
  }

  return newInitialValues;
};

export const getDuplicateData = (planName = '', data?: ManagePlanDetail) => {
  if (data) {
    const duplicateData: ManagePlanDetail = {
      ...data,
      planName,
      viewStatus: 'draft',
    };
    return duplicateData;
  }
  return undefined;
};

export const getDuplicatePlanName = (
  originalName: string,
  manageTabState: Pick<TabState, 'planName'>[],
  plans: Pick<ManagePlan, 'planName'>[],
) => {
  let planName = `Copy of ${originalName}`;
  let existingNamesCount = manageTabState.reduce(
    (count, tab) => (tab.planName.includes(planName) ? count + 1 : count),
    0,
  );
  existingNamesCount = plans.reduce(
    (count, plan) => (plan.planName.includes(planName) ? count + 1 : count),
    existingNamesCount,
  );
  if (existingNamesCount) planName = `${planName} (${existingNamesCount})`;
  return planName;
};

export const getNewStatus = (
  viewStatus: PlanStatus,
  status?: PlanStatus,
): PlanStatus => {
  if (viewStatus === 'archived') return 'draft';
  if (viewStatus === 'draft' && status === 'draft') return 'active';
  return status ?? 'draft';
};

const generalPlanMath = [
  ['Adult', '(D1110 x 2) + (D0120 x 2) + D0274'],
  ['Child', '(D1120 x 2) + (D0120 x 2) + D0272'],
  ['Perio', '(D4910 x 4) + (D0120 x 2) + D0274'],
];
const pediatricPlanMath = [
  ['Toddler', '(D1120 x 2) + (D0145 x 2) + D0272'],
  ['Child', '(D1120 x 2) + (D0120 x 2) + D0272'],
  ['Teen', '(D1110 x 2) + (D0120 x 2) + D0274'],
];
const periodontistPlanMath = [
  ['Perio 1', 'D4910 + D0120 + D0274'],
  ['Perio 2', '(D4910 x 2) + (D0120 x 2) + D0274'],
  ['Perio 4', '(D4910 x 4) + (D0120 x 2) + D0274'],
];

const mathTextsDhq: Partial<Record<PresetType, string[]>> = {
  general_adult: generalPlanMath[0],
  general_child: generalPlanMath[1],
  general_perio: generalPlanMath[2],

  pediatric_toddler: pediatricPlanMath[0],
  pediatric_child: pediatricPlanMath[1],
  pediatric_teen: pediatricPlanMath[2],

  periodontic_perio1: periodontistPlanMath[0],
  periodontic_perio2: periodontistPlanMath[1],
  periodontic_perio4: periodontistPlanMath[2],
  voucher_patient: [],
  voucher_patient_established: [],
};

const generalExamMath = [
  'Exam & Eyewear',
  '92014 + 92015 + S9986 = Your Total',
];
const refractionPlanMath = [
  'Eyewear Only + Contacts',
  '92015 + S9986 + 92310 (bill exam to medical ins)  = Your Total',
];
const examEyewearPlanMath = [
  'Exam & Eyewear + Contacts',
  '92014 + 92015 + S9986 + 92310  = Your Total',
];
const refractionEyewearPlanMath = [
  'Eyewear Only',
  '92015 + S9986 (bill exam to medical ins) = Your Total',
];

const mathTextsVhq: Partial<Record<PresetType, string[]>> = {
  general_exam: generalExamMath,
  general_refraction: refractionPlanMath,
  general_exam_eyewear: examEyewearPlanMath,
  refraction_eyewear: refractionEyewearPlanMath,
};

const mathTextsTenants: TenantStruct<Partial<Record<PresetType, string[]>>> = {
  dhq: mathTextsDhq,
  vhq: mathTextsVhq,
};

export const mathTexts = mathTextsTenants[TENANT.id];

const preparePricesVhq = (data: Partial<CodesTypes>): SubmitCode[] => {
  return UCRCodesVHQ.map((code) => ({
    id: UCRCodesIds[code],
    code,
    price: getUCRData(data, code),
  }));
};

const preparePricesDhq = (data: Partial<CodesTypes>): SubmitCode[] => {
  return UCRCodesDHQ.map((code) => ({
    id: UCRCodesIds[code],
    code,
    price: getUCRData(data, code),
  }));
};

const preparePricesTenant: TenantStruct<
  typeof preparePricesVhq | typeof preparePricesDhq
> = {
  vhq: preparePricesVhq,
  dhq: preparePricesDhq,
};

export const preparePrices = preparePricesTenant[TENANT.id];
