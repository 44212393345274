import { NamedRoute } from 'new/routing/types';

export type DisputesRouteName = 'Disputes';

export const disputesRoutes: NamedRoute[] = [
  {
    name: 'Disputes',
    path: `/account/disputes`,
  },
];
