/*
ShowStripe Form Modal Component
================================================================================
*/

/*
Import
------------------------------------------------------------
*/
// libs
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Modal from 'react-bootstrap/lib/Modal';
import Row from 'react-bootstrap/lib/Row';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import { Field, reduxForm, submit as submitForm } from 'redux-form';
import Popover from 'react-bootstrap/lib/Popover';
import FaClose from 'react-icons/lib/fa/close';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

// app
import LabeledInput from 'components/LabeledInput';

// local
import styles from './styles.module.css';
import ShowStripeFormValidator from './validator';

/*
Redux
------------------------------------------------------------
*/

function mapStateToProps (state) {
  return {
  }
}

const mapDispatchToProps = (dispatch) => ({
  submit: () => dispatch(submitForm('ShowStripe')),
});

/*
ShowStripe Form Modal
================================================================================
*/
@connect(mapStateToProps, mapDispatchToProps)
@reduxForm({
  form: 'ShowResetStripe',
  enableReinitialize: true,
  validate: ShowStripeFormValidator,
})
@CSSModules(styles,{allowMultiple:true})
export default class ShowStripeResetModal extends React.Component {


  static propTypes = {
    // form related - passed in
    initialValues: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,

    // modal related - passed in
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,

  }
  constructor(props) {
    super(props);
    this.state={
      soloAccountMemberConfirmation:false
    }
  }

  getLabeledInput(props) {
    return new LabeledInput(props);
  }

  checkPause=()=>{
    this.setState({
      ...this.state,
      soloAccountMemberConfirmation: true,
    });
  }


  clearSoloAccountMemberConfirmation = () => {
    this.setState({
      soloAccountMemberConfirmation: false,
    });
  };
  /*
  Render
  ------------------------------------------------------------
  */
  render() {
    const {
      // form related
      initialValues,
      handleSubmit,
      submit,
      submitting,

      // modal related
      show,
      onHide,
    } = this.props;

    const {
      soloAccountMemberConfirmation
    }=this.state;

    const soloAccountMemberConfirmationPopover = soloAccountMemberConfirmation === false
      ? null
      : (<Popover
        className="popover--large"
        id="solo-account-member-confirmation-popover"
        placement="left"
        positionLeft={68}
        positionTop={-44}
        title="Did you catch that?"
      >
          <p>Once you proceed, <span styleName="error">you will not be able to enroll new members until you've created the new account.</span> Please make sure you have everything before proceeding.</p>
        <div styleName="popover__controls">
          <span
            styleName="popover__control popover__control--close"
            onClick={this.clearSoloAccountMemberConfirmation}
          >
            <FaClose />
          </span>

          <input
            type="button"
            styleName="popover__control button--short"
            onClick={this.props.onSubmit}
            value="Proceed"
          />
        </div>
      </Popover>
      );


    return (
      <Modal
        backdrop={'static'}
        bsSize={'lg'}
        onHide={onHide}
        show={show}
      >
        {/*
        Modal Header
        ------------------------------------------------------------
        */}
        {(moment().format('X')>1627923909)?
        ((cookies.get('beccascookie')? (<Modal.Header closeButton>
          <Modal.Title>Upgrade your Stripe Account</Modal.Title>
        </Modal.Header>) : (<Modal.Header>
          <Modal.Title>Upgrade your Stripe Account</Modal.Title>
        </Modal.Header>))
        ):(
        <Modal.Header closeButton>
          <Modal.Title>Upgrade your Stripe Account</Modal.Title>
        </Modal.Header>)}

        {/*
        Modal Body
        ------------------------------------------------------------
        */}
        <Modal.Body>
          <form
            onSubmit={handleSubmit(this.checkPause)}
            className="form-horizontal"
            id="ShowStripe-form"
          >

            <h4><b>Before you start, make sure you have the following:</b></h4>
        <p>
          <ul>
            <li>Legal business name and EIN (Employer Identification Number)</li>
            <li>The name on the bank account (typically your legal business name), routing number, and account number</li>
            <li>Practice Owner's: name, email, mobile phone, DOB, full SSN, and home address</li>
          </ul>
          Yes, you need ALL of that to confirm and validate your new account. Once you begin the upgrade, enrollments will be paused until you complete the process.
        </p>
        <p styleName="error">On August 2nd, you will no longer be able to close out of this window.</p>
          <div styleName="popover__container">
                      {soloAccountMemberConfirmationPopover}
                    </div>
                   </form>
        </Modal.Body>

        {/*
        Modal Footer
        ------------------------------------------------------------
        */}
        <Modal.Footer>
          <div className="modal-controls">
            <input
              type="button"
              className="modal-control"
              disabled={submitting}
              onClick={this.checkPause}
              value="Proceed to Setup and Pause Enrollment"
            />
          </div>
        </Modal.Footer>

      </Modal>
    );

  }
}
