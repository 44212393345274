import React from 'react';
import moment from 'moment';

const {useState, useEffect} = React;

const Countdown = (values) => {
  //.add(values.insertedMinutes,'minutes')
  const [countdownDate, setCountdownDate] = useState(moment().add(values.insertedMinutes,'minutes').format('X')*1000);
  const [state, setState] = useState({
    minutes: 5,
    seconds: 0,
    expire:false
  });

  useEffect(() => {
    setInterval(() => updateCountdown(), 1000);
  }, []);

  const updateCountdown = () => {
    if (countdownDate) {
      // Get the current time
      const currentTime = new Date().getTime();
  
      // Get the time remaining until the countdown date
      const distanceToDate = countdownDate - currentTime;
  
      // Calculate days, hours, minutes and seconds remaining
      let minutes = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
      );
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);
  
      // For visual appeal, add a zero to each number that's only one digit
      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  
      if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`;
      }
      if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`;
      }
  
      // Set the state to each new time
      setState({  minutes, seconds });
    }
  }

  return (
    <div>
      <div className='countdown-wrapper'>
        <div className='time-section'>
          <div className='time'>{state.minutes || '00'}:{state.seconds || '00'}</div>
        </div>
      </div>
    </div>
  );
};

export default Countdown;