import { createEndpoint } from 'new/api/utils';
import { api } from 'new/api/axios';
import {
  ManageGroup,
  GroupDetailWithPlans,
  GroupName,
  SetGroupPlanSettingsRequest,
} from 'new/modules/ManageGroups/types';

export const getLocationGroups = createEndpoint<
  ManageGroup[],
  { organizationId: number }
>({
  url: '/multi-location/organization-dentist-groups',
  endpoint: ({ url, params }) => api.post<ManageGroup[]>(url, params),
});

export const getGroupDetails = createEndpoint<
  GroupDetailWithPlans,
  { userId: string }
>({
  url: '/multi-location/dentist-group-detail/:id',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const removeGroup = createEndpoint({
  url: '/multi-location/dentist-group-detail/:id',
  endpoint: ({ url }) => api.delete(url),
});

export const updateGroupName = createEndpoint<unknown, GroupName>({
  url: '/multi-location/dentist-group-detail/:id',
  endpoint: ({ url, params }) => api.put(url, params),
});

export const setGroupPlanSettings = createEndpoint<
  unknown,
  SetGroupPlanSettingsRequest
>({
  url: '/multi-location/dentist-groups-settings',
  endpoint: ({ url, params }) => api.post(url, params),
});
