import React, { ReactNode } from 'react';
import { styled, Box, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { formatNumberAsCurrency } from 'new/utils/numbers';
import Button from '../Button/Button';
import {
  ModalContainer,
  MainMessageText,
  SecondaryMessageText,
  AdditionalButtonText,
  PriceMessage,
  ModalContent,
  UnderButtonText,
  Title,
  Subtitle,
  SuccessMessage,
} from './styles';

export type SuccessConfigProps = {
  onClose: () => void;
  title?: string;
  subtitle?: string;
  buttonLabel?: string;
  mainMessage?: ReactNode;
  secondaryMessage?: ReactNode;
  successPriceMessage?: number;
  buttonStyle?: 'primary' | 'secondary' | 'change';
  additionalButtonText?: string;
  onConfirm?: () => void;
  secondaryButtonLabel?: string;
  customButtons?: ReactNode;
  underButtonText?: string;
  isChangePlan?: boolean;
};

const SuccessModalContent: React.FC<SuccessConfigProps> = ({
  onClose,
  title,
  subtitle,
  buttonLabel,
  mainMessage,
  secondaryMessage,
  successPriceMessage,
  buttonStyle,
  additionalButtonText,
  onConfirm,
  customButtons,
  underButtonText,
  isChangePlan,
}) => (
  <ModalContainer>
    <ModalContent>
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      <Box
        color="#fff"
        borderRadius="50%"
        width={80}
        height={80}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: 'success.main' }}
      >
        <FontAwesomeIcon icon={faCheck as IconProp} size="3x" />
      </Box>
      {isChangePlan ? 
      <SuccessMessage> SUCCESS! </SuccessMessage>
      :
      successPriceMessage && <SuccessMessage> SUCCESS! </SuccessMessage>
      }
      <MainMessageText>{mainMessage}</MainMessageText>
      <SecondaryMessageText>{secondaryMessage}</SecondaryMessageText>
      {successPriceMessage && <PriceMessage> {formatNumberAsCurrency(successPriceMessage, 2, true)} </PriceMessage>}
    </ModalContent>
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="flex-end"
      gap={5}
      sx={{
        width: '100%',
        height: 100,
        position: 'relative',
      }}
    >
      {customButtons || (
        <Button
          sx={{ width: 'fit-content', mt: 4 }}
          onClick={onConfirm || onClose}
          variant={buttonStyle === 'change' ? 'outlined' : 'secondary' ? 'outlined' : 'contained'}
          color={buttonStyle === 'change' ? 'primary' : 'secondary' ? 'secondary' : 'primary'}
        >
          {buttonLabel}
        </Button>
      )}
      {additionalButtonText && (
        <AdditionalButtonText>{additionalButtonText}</AdditionalButtonText>
      )}
    </Stack>
    {underButtonText && (
      <UnderButtonText className="underButtonText">
        {underButtonText}
      </UnderButtonText>
    )}
  </ModalContainer>
);

export default SuccessModalContent;
