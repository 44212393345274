import { NamedRoute } from '../../routing/types';

export type MembersRoutesName = 'MembersPage';

const MEMBERS_BASE_URL = '/account/members';

export const membersRoutes: NamedRoute[] = [
  {
    name: 'MembersPage',
    path: `${MEMBERS_BASE_URL}`,
    getPath: (id) => `${MEMBERS_BASE_URL}${id ? `?id=${id}` : ''}`,
  },
];
