import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ThemeProvider from './ThemeProvider/ThemeProvider';
import MockApiProvider from './MockApiProvider';
import useGPlacesApi from 'new/hooks/useGPlacesApi';
import ToastContainer from 'new/components/ToastContainer';

import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import I18n from 'new/i18n/I18n';

const Providers: React.FC = ({ children }) => {
  useGPlacesApi();
  console.log('Providers');
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider>
          <MockApiProvider>
            <I18n>
              {children}
              <ToastContainer />
            </I18n>
          </MockApiProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  );
};

export default Providers;
