import { NamedRoute } from '../../routing/types';

export type BusinessConnectRouteName = 'BusinessConnectPage';

export const businessConnectRoutes: NamedRoute[] = [
  {
    name: 'BusinessConnectPage',
    path: '/account/business-connect',
    permission: '_all',
  },
];
