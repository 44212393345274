import { api } from 'new/api/axios';
import { createEndpoint } from 'new/api/utils';
import {
  ChangeTargetRequest,
  ChangeTargetResponse,
  GetLegacyPlansListRequest,
  GetLegacyPlansListResponse,
  GetMultiPlanSettingsRequest,
  GuaranteedPlanPaymentsRequest,
  GuaranteedPlanPaymentsToggleRequest,
  ManagePlan,
  ManagePlanDetail,
  ManagePlansSettings,
  MergeLegacyPlansRequest,
  UpdatePlanPricingMultiRequest,
  UpdatePlanPricingMultiResponse,
  UpdatePlanStatusMultiRequest,
  WorryFreeResponse,
} from 'new/modules/ManagePlans/types';
import {
  MembershipSettingsRequest,
  Plan,
} from '../MembershipPlansManage/types';

export const getPlansList = createEndpoint<ManagePlan[], unknown>({
  url: '/multi-location/manage-plans/:id',
  endpoint: ({ url }) => api.get<ManagePlan[]>(url),
});

export const getSimplePlansList = createEndpoint<
  { custom_memberships: Plan[] },
  unknown
>({
  url: '/users/:id/dentist-info/all-plans/upgrade',
  endpoint: ({ url }) => api.post<{ custom_memberships: Plan[] }>(url),
});

export const getGroupPlansList = createEndpoint<ManagePlan[], unknown>({
  url: '/multi-location/group-plans/:id',
  endpoint: ({ url }) => api.get<ManagePlan[]>(url),
});

export const changeMembershipSettingsML =
  createEndpoint<MembershipSettingsRequest>({
    url: '/multi-location/organization-plan-settings',
    endpoint: ({ url, params }) =>
      api.post<MembershipSettingsRequest>(url, params),
  });

export const getMultiPlanSettings = () =>
  createEndpoint<ManagePlansSettings, GetMultiPlanSettingsRequest>({
    url: `/multi-location/multi-plan-settings`,
    endpoint: ({ url, params }) => api.post<ManagePlansSettings>(url, params),
  });

export const getMasterPlanDetail = createEndpoint<ManagePlanDetail>({
  url: '/multi-location/master-plan-details/:id',
  endpoint: ({ url }) => api.get<ManagePlanDetail>(url),
});

export const updatePlanStatus = createEndpoint<UpdatePlanStatusMultiRequest>({
  url: '/multi-location/multi-master-plan-save-state',
  endpoint: ({ url, params }) =>
    api.post<UpdatePlanStatusMultiRequest>(url, params),
});

export const updatePlanPricing = createEndpoint<
  UpdatePlanPricingMultiResponse,
  UpdatePlanPricingMultiRequest
>({
  url: 'multi-location/change-master-plan-price',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const getLegacyPlansList = createEndpoint<
  GetLegacyPlansListResponse,
  GetLegacyPlansListRequest
>({
  url: '/multi-location/get-legacy-org-plans',
  endpoint: ({ url, params }) =>
    api.post<GetLegacyPlansListResponse>(url, params),
});

export const mergeLegacyPlans = createEndpoint<
  unknown,
  MergeLegacyPlansRequest
>({
  url: '/multi-location/merge-legacy-plans',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const setGuaranteedPlanPaymentsEndpoint = createEndpoint<
  unknown,
  GuaranteedPlanPaymentsRequest
>({
  url: '/dentists/guaranteed-plan-payments',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const toggleGuaranteedPlanPaymentsEndpoint = createEndpoint<
  unknown,
  GuaranteedPlanPaymentsToggleRequest
>({
  url: '/dentists/guaranteed-plan-payments/toggle',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const changeTargetEndpoint = createEndpoint<
  ChangeTargetResponse,
  ChangeTargetRequest
>({
  url: '/multi-location/change-target',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const getDentistWorryFreeEndpoint = createEndpoint<WorryFreeResponse>({
  url: '/dentists/:id/custom-memberships/getDentistWorryFree',
  endpoint: ({ url }) => api.get<WorryFreeResponse>(url),
});
