import { NamedRoute } from '../../routing/types';

export type LoginRouteName = 'LoginPage' | 'ForgotPage' | 'ResetPage';

export const loginRoutes: NamedRoute[] = [
  {
    name: 'LoginPage',
    path: '/accounts/login',
    permission: '_all',
  },
  {
    name: 'ForgotPage',
    path: '/accounts/forgot-password',
    permission: '_all',
  },
  {
    name: 'ResetPage',
    path: '/accounts/reset-password/:token',
    permission: '_all',
  },
];
