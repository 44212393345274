import { createSelector } from 'reselect';
import get from 'lodash/get';

/**
 * Direct selector to the app state domain
 */
function selectGlobal (state) {
  return state.global;
}

const selectCurrentUser = createSelector(
  selectGlobal,
  (substate) => {
    let currentUser = get(substate, 'currentUser')
    return currentUser;
  }
);

const selectGlobalBanner = createSelector(
  selectGlobal,
  (substate) => {
    let globalBanner = get(substate, 'currentUser.globalBanner')
    return globalBanner;
  }
);

const selectUserType = createSelector(
  selectGlobal,
  (substate) => get(substate, 'currentUser.type'),
);

const selectUserId = createSelector(
  selectGlobal,
  (substate) => get(substate, 'currentUser.id'),
);

const selectUserName = createSelector(
  selectGlobal,
  (substate) => {
    const firstName = get(substate, 'currentUser.firstName');
    const lastName = get(substate, 'currentUser.lastName');
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    return null;
  }
);

const selectUserNameParts = createSelector(
  selectGlobal,
  (substate) => (
    {
      firstName: get(substate, 'currentUser.firstName'),
      middleName: get(substate, 'currentUser.middleName'),
      lastName: get(substate, 'currentUser.lastName')
    }
  )
);

const selectAuthState = createSelector(
  selectGlobal,
  (substate) => substate.loggedIn,
);

const selectAuthLoadingState = createSelector(
  selectGlobal,
  (substate) => substate.loggedIn && !substate.currentUser,
);

const selectServices = createSelector(
  selectGlobal,
  (substate) => get(substate, 'services')
);

const selectPageTitle = createSelector(
  selectGlobal,
  (substate) => get(substate, 'pageTitle')
);

const selectPlaidLinkToken = createSelector(
  selectGlobal,
  (substate) => get(substate, 'plaidLinkToken')
);

const dentistSpecialtiesSelector = createSelector(
  selectGlobal,
  (substate) => get(substate, 'dentistSpecialties')
);

const pricingCodesSelector = createSelector(
  selectGlobal,
  (substate) => get(substate, 'pricingCodes'),
);

const selectSFLeadSubmit = createSelector(
  selectGlobal,
  (substate) => get(substate, 'leadSubmitted'),
);


const selectAssociations = createSelector(
  selectGlobal,
  (substate) => substate.associations
);

const selectFetchingAssociations = createSelector(
  selectGlobal,
  (substate) => substate.fetchingAssociations
);

const selectFetchingPricingCodes = createSelector(
  selectGlobal,
  (substate) => substate.fetchingPricingCodes
);

const selectFetchingServices = createSelector(
  selectGlobal,
  (substate) => substate.fetchingServices
);

const selectFetchingSpecialties = createSelector(
  selectGlobal,
  (substate) => substate.fetchingSpecialties
);


export {
  selectGlobal,
  selectUserType,
  selectUserId,
  selectUserName,
  selectUserNameParts,
  selectAuthState,
  selectAuthLoadingState,
  selectCurrentUser,
  selectPageTitle,
  selectServices,
  selectPlaidLinkToken,
  dentistSpecialtiesSelector,
  pricingCodesSelector,
  selectSFLeadSubmit,
  selectAssociations,
  selectFetchingAssociations,
  selectFetchingPricingCodes,
  selectFetchingServices,
  selectFetchingSpecialties,
  selectGlobalBanner
};

