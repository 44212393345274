import React, { useContext, useRef } from 'react';
import MockAdapter from 'axios-mock-adapter';

import { DEFAULT_RESPONSE_TIME } from '../__mocks__/constants';
import { api } from '../api/axios';

const MockApiContext = React.createContext<MockAdapter | undefined>(undefined);

const MockApiProvider: React.FC = ({ children }) => {
  const mockApi = useRef(
    new MockAdapter(api, {
      delayResponse: DEFAULT_RESPONSE_TIME,
      onNoMatch: 'passthrough',
    }),
  );

  return (
    <MockApiContext.Provider value={mockApi.current}>
      {children}
    </MockApiContext.Provider>
  );
};

const useMockApiContext = () => useContext(MockApiContext);

export default MockApiProvider;

export { useMockApiContext };
