import { AxiosResponse } from 'axios';
import findRoute from 'new/routing/routes';
import { toast } from 'react-toastify';
import { ResponseWithMessage } from './types';
import { history } from 'app';

const loginRoute = findRoute('LoginPage');

export const successHandler = (response: AxiosResponse) => {
  const hasMessage = (response.data as ResponseWithMessage)?._message;
  /* eslint-disable */
  const error = (response.data as any)?.data?.error;
  /* eslint-enable */

  if (error === 'Failed to authenticate') {
    console.log(response);
    toast.error('Failed to authenticate', { toastId: 'auth-error' });
    history.push(loginRoute);
  }

  if (hasMessage) {
    toast.success((response.data as ResponseWithMessage)._message);
  }

  return response;
};

export const successHandlerNoToast = (response: AxiosResponse) => {
  /* eslint-disable */
  const error = (response.data as any)?.data?.error;
  /* eslint-enable */

  if (error === 'Failed to authenticate') {
    console.log(response);
    toast.error('Failed to authenticate', { toastId: 'auth-error' });
    history.push(loginRoute);
  }

  return response;
};
