import { api } from 'new/api/axios';
import { createEndpoint } from 'new/api/utils';
import {
  ManagePlanDetail,
  UpdatePlanPricingMultiRequest,
  UpdatePlanPricingMultiResponse,
  UpdatePlanStatusMultiRequest,
} from '../ManagePlans/types';
import {
  ChangePlansOrderRequest,
  ChangePlansOrderResponse,
  GetPlanDetailRequest,
  MembershipSettingsRequest,
  PlansRequestResponse,
  RecommendedPriceResponse,
} from './types';

export const getPlans = createEndpoint<PlansRequestResponse>({
  url: '/users/:id/dentist-info/all-plans/upgrade',
  endpoint: ({ url }) => api.post(url),
});

export const getPlanDetail = (dentistInfoId: number) =>
  createEndpoint<ManagePlanDetail, GetPlanDetailRequest>({
    url: `/users/${dentistInfoId}/dentist-info/membershipPlanDetail`,
    endpoint: ({ url, params }) => api.post<ManagePlanDetail>(url, params),
  });

export const changePlansOrder = createEndpoint<
  ChangePlansOrderResponse,
  Omit<ChangePlansOrderRequest, 'dentistInfoId'>
>({
  url: '/dentists/:id/memberships/sort',
  endpoint: ({ url, params }) =>
    api.post<ChangePlansOrderResponse>(url, params),
});

export const changeMembershipSettingsSL =
  createEndpoint<MembershipSettingsRequest>({
    url: '/dentists/updateMembershipSettings',
    endpoint: ({ url, params }) =>
      api.post<MembershipSettingsRequest>(url, params),
  });

export const recommendedPricing = createEndpoint<RecommendedPriceResponse>({
  url: '/pricing',
  endpoint: ({ url }) => api.get(url),
});

export const updatePlanStatus = createEndpoint<UpdatePlanStatusMultiRequest>({
  url: '/dentist-info/membership-plan-save-state',
  endpoint: ({ url, params }) =>
    api.post<UpdatePlanStatusMultiRequest>(url, params),
});

export const updatePlanPricing = (dentistInfoId: number) =>
  createEndpoint<UpdatePlanPricingMultiResponse, UpdatePlanPricingMultiRequest>(
    {
      url: `/dentists/${dentistInfoId}/custom-memberships/changePlanPrice`,
      endpoint: ({ url, params }) => api.post(url, params),
    },
  );
