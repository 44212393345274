import { NamedRoute } from 'new/routing/types';
import { RouteName, routes } from 'new/routing/routesList';

const findRoute = (name: RouteName): NamedRoute => {
  const route = routes.find((route) => route.name === name);

  if (!route) {
    throw new Error(`There's no Route with name < ${name} > defined in app`);
  }

  return route;
};

export default findRoute;
