import { Dispatch, SetStateAction, createContext } from 'react';
import { Locale } from './types';

export const LocaleContext = createContext<{
  locale: Locale;
  setLocale: Dispatch<SetStateAction<Locale>>;
}>({
  locale: 'en-US',
  setLocale: () => undefined,
});
