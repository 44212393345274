/*
NavBar Component
================================================================================
NOTE: Add custom left-hand-side elements for routes in the `returnLinks`
      dictionary (a class field).
*/

/*
Imports
------------------------------------------------------------
*/
// libs
import React from 'react';
import PropTypes from 'prop-types';
// import Image from 'react-bootstrap/lib/Image';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { push } from 'react-router-redux';
import FaHome from 'react-icons/lib/fa/home';
import FaFile from 'react-icons/lib/fa/file';
import FaWrench from 'react-icons/lib/fa/wrench';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';

import { getItem, removeItem } from 'utils/localStorage';

// app
import { selectCurrentUser } from 'containers/App/selectors';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

// local
import styles from './styles.module.css';

/*
Redux
------------------------------------------------------------
*/
function mapStateToProps (state) {
  return {
    loggedInUser: selectCurrentUser(state),
    user: selectCurrentUser(state),
  };
}

function mapDispatchToProps (dispatch) {
  return {
    changeRoute: (url) => dispatch(push(url)),
  }
}


/*
NavBar
================================================================================
*/
@connect(mapStateToProps, mapDispatchToProps)
@CSSModules(styles, { allowMultiple: true })
export default class BusinessSideNavDashboard extends React.Component {

  static propTypes = {
    changeRoute: PropTypes.func,
    loggedInUser: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ]),
    pathname: PropTypes.string,
    logo: PropTypes.oneOfType([
      PropTypes.string, // show custom logo
      PropTypes.bool,   // false => show no logo (i.e. custom logo url is loading)
    ]),
    user: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
    ]),


  }

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.user) {
    }
  }


  returnLinks = {
    '/accounts/dentist-signup': (<Link to="/" styleName="navbar__text">&lt; Home</Link>),
    '/accounts/login': (<Link to="/accounts/login" styleName="navbar__text">&lt; Login</Link>),
  }


  selectTab = (key) => {
    switch (key) {
      case 'members':
        this.props.changeRoute('/business/members');
        break;
      case 'reports':
        this.props.changeRoute('/business/reports');
        break;
      case 'settings':
      this.props.changeRoute('/business/settings');
        break;    
      default:
        // Don't do anything... we should never actually reach here.
        break;
    }
  }


  render () {
    const { 
      logo,
      user,
      pathname,
     } = this.props;
    const { firstName, lastName, avatar, } = this.props.loggedInUser;

    const fullName = `${firstName} ${lastName}`;

    // else - show no logo
 
      return (
        <div styleName="sidebar">
          <Accordion>
            <AccordionItem onClick={() =>this.selectTab('members')}  >
              <AccordionItemTitle>
              <FaHome/>&emsp;Dashboard
              </AccordionItemTitle>
            </AccordionItem>

            <AccordionItem onClick={() =>this.selectTab('reports')}  >
              <AccordionItemTitle>
              <FaFile/>&emsp;Reports
              </AccordionItemTitle>
            </AccordionItem>

            <AccordionItem onClick={() =>this.selectTab('settings')}  >
              <AccordionItemTitle>
              <FaWrench/>&emsp;Settings
              </AccordionItemTitle>
            </AccordionItem>
            {this.props.user!=undefined&&window.location.href.indexOf('localhost') !=-1&&(
            <AccordionItem    >
              <AccordionItemTitle>
              <a target="_blank" href={`https://dashboard.stripe.com/test/customers/${this.props.user.stripeCustomerId}`}>
              Stripe</a>
              </AccordionItemTitle>
            </AccordionItem>
            )}
          </Accordion>
        </div>
      );
  }
}
