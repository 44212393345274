import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';

import CSSModules from 'react-css-modules';


import styles from './styles.module.css';
import SupportForm from './SupportForm';


import request from 'utils/request';


@CSSModules(styles,{allowMultiple:true})
export default class InviteFormModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props);
    this.state = {
      submitting: false,
      error: null
    };
  }


  submitReport = (vals) => {
    this.setState({ submitting: true, error: null });
  };

  render () {
    const {
      error,
      show,
      onHide,
    } = this.props;
    const { submitting } = this.state;

    return (
      <Modal
        backdrop={'static'}
        bsSize={'lg'}
        onHide={onHide}
        show={show}
      >
        <Modal.Header closeButton>
          <Modal.Title>Report Issue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SupportForm
            error={error}
            onSubmit={this.submitReport}
            submitting={submitting}
          />
        </Modal.Body>
      </Modal>
    );

  }
}
