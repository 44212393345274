import { SelectOption } from 'new/components/Form/Select';

export const scriptingPdf =
  'https://dentalhq-files.s3.amazonaws.com/DHQ+Scripting+(2).pdf';

export const pmsPlanOptions: SelectOption[] = [
  { value: 'archy', label: 'Archy' },
  { value: 'abelDent', label: 'ABELDent' },
  { value: 'carestack', label: 'CareStack' },
  { value: 'cloud9', label: 'Cloud 9' },
  { value: 'curve', label: 'Curve' },
  { value: 'datacon', label: 'Datacon' },
  { value: 'denticon', label: 'Denticon' },
  { value: 'dentimax', label: 'DentiMax' },
  { value: 'dentisoft', label: 'Dentisoft' },
  { value: 'dentrix', label: 'Dentrix' },
  { value: 'dentrixAscend', label: 'Dentrix Ascend' },
  { value: 'dentrixEnterprise', label: 'Dentrix Enterprise' },
  { value: 'dolphin', label: 'Dolphin' },
  { value: 'dovetail', label: 'Dovetail' },
  { value: 'doxPedo', label: 'DOX|Pedo' },
  { value: 'eaglesoft', label: 'Eaglesoft' },
  { value: 'iDentalSoft', label: 'iDentalSoft' },
  { value: 'openDental', label: 'Open Dental' },
  { value: 'practiceWeb', label: 'Practice-Web' },
  { value: 'practiceWorks', label: 'PracticeWorks' },
  { value: 'softdent', label: 'SoftDent' },
  { value: 'tab32', label: 'tab32' },
  { value: 'xlDent', label: 'XLDent' },
  { value: 'yapi', label: 'Yapi' },
  { value: 'other', label: 'Other' },
];

export const launchChecklistItems: Record<number, string> = {
  1: 'Finalize and activate your\nplans & pricing',
  2: '<a href="/account/marketing">Print and display QR Code signs</a>',
  3: 'Watch <a href="https://dentalhq-uploads.s3.amazonaws.com/DHQ+Revised+Q3+2024+TT+.mp4" target="_blank">Team Training Video</a>',
  4: 'Additional questions? Email\n<a href="mailto:success@dentalhq.com" target="_blank">success@dentalhq.com</a>',
  5: 'Send <a href="/account/marketing">email and text blasts</a> to all patients to promote your Membership Program',
  6: 'Create call-to-action on your website linking to the Patient Sign-Up Page',
  7: 'Schedule first Monthly and Quarterly progress calls',
  8: `<b>If you <a href=${scriptingPdf} target="_blank">tell your patients</a> about your plan, they will join.</b>`,
};

export const leadSourceOptions = [
  'American Association of Dental Office Managers (AADOM)',
  'American Academy of Pediatric Dentistry (AAPD)',
  'Association of Dental Support Organizations (ADSO)',
  'Dentist Entrepreneur Organization (DEO)',
  'Elite Dental Alliance',
  'Mommy Dentists in Business (MDIB)',
  'North Carolina Dental Society (NCDS)',
  'Ohio Dental Association (ODA)',
  'Oklahoma Dental Association (OKDA)',
  'Texas Dental Association (TDA)',
  'Gargle',
  'Google',
  'Nifty Thrifty',
  'Smile Source',
  'The Levin Group | Roger Levin',
];

export const locationInfoList2 = [
  'UCR Codes for all preventive services (aka “D-Codes”)',
];

export const templateOptions = [
  {
    value: '0',
    label: 'Classic',
    imageURL:
      'https://s3.amazonaws.com/market_materials/get-started-preview1.png',
  },
  {
    value: '1',
    label: 'Blue & Green',
    imageURL:
      'https://s3.amazonaws.com/market_materials/get-started-preview2.png',
  },
  {
    value: '2',
    label: 'Navy & Orange',
    imageURL:
      'https://s3.amazonaws.com/market_materials/get-started-preview3.png',
  },
  {
    value: '3',
    label: 'Purple & Gray',
    imageURL:
      'https://s3.amazonaws.com/market_materials/get-started-preview4.png',
  },
];

export const jobTitleOptions: SelectOption[] = [
  {
    value: 'officeManager',
    label: 'Office Manager',
  },
  {
    value: 'frontDesk',
    label: 'Front Desk',
  },
  {
    value: 'treatmentCoordinator',
    label: 'Treatment Coordinator',
  },
  {
    value: 'financeInsuranceCoordinator',
    label: 'Finance / Insurance Coordinator',
  },
  {
    value: 'regionalManager',
    label: 'Regional Manager',
  },
  {
    value: 'marketingGuru',
    label: 'Marketing Guru',
  },
  {
    value: 'dentist',
    label: 'Dentist',
  },
  {
    value: 'executive',
    label: 'Executive',
  },
  {
    value: 'controller',
    label: 'Controller',
  },
  {
    value: 'dentalAssistant',
    label: 'Dental Assistant',
  },
  {
    value: 'hygienist',
    label: 'Hygienist',
  },
  {
    value: 'other',
    label: 'Other',
  },
];
