import { createEndpoint } from 'new/api/utils';
import { api } from 'new/api/axios';
import {
  AddLocationToGroupRequest,
  ManageLocation,
  LocationDetails,
  RemoveLocationFromGroupRequest,
} from './types';

export const getLocations = createEndpoint<
  ManageLocation[],
  { organizationId: number }
>({
  url: '/multi-location/manage-locations',
  endpoint: ({ url, params }) => api.post<ManageLocation[]>(url, params),
});

export const getLocationDetails = createEndpoint<LocationDetails>({
  url: '/multi-location/manage-location-detail/:id',
  endpoint: ({ url }) => api.post(url),
});

export const addLocationToGroup = createEndpoint<
  { name: string },
  AddLocationToGroupRequest
>({
  url: '/multi-location/dentist-groups',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const removeLocationFromGroup = createEndpoint<
  { name: string },
  RemoveLocationFromGroupRequest
>({
  url: '/multi-location/dentist-groups',
  endpoint: ({ url, params }) => api.delete(url, { data: params }),
});
