import { Id } from 'new/api/types';
import {
  claimGuarantee,
  clearDelinquency,
} from 'new/modules/MembersPage/endpoints';
import { useMutation, useQuery } from 'react-query';
import create, { GetState, SetState } from 'zustand';
import { StoreApiWithPersist, persist } from 'zustand/middleware';
import {
  TabState,
  TargetType,
  UpdatePlanPricingMultiRequest,
  UpdatePlanPricingMultiResponse,
  UpdatePlanStatusMultiRequest,
} from '../ManagePlans/types';
import {
  changeMembershipSettingsSL,
  changePlansOrder,
  getPlanDetail,
  getPlans,
  recommendedPricing,
  updatePlanPricing,
  updatePlanStatus,
} from './endpoints';
import {
  ChangePlansOrderRequest,
  ChangePlansOrderResponse,
  GetPlanDetailRequest,
  MembershipPlansStore,
  MembershipSettingsRequest,
} from './types';

export const useMembershipPlansTabsStore = create<
  MembershipPlansStore,
  SetState<MembershipPlansStore>,
  GetState<MembershipPlansStore>,
  StoreApiWithPersist<MembershipPlansStore>
>(
  persist(
    (set) => ({
      membershipPlansTabs: [] as TabState[],
      setMembershipPlansTabs: (membershipPlansTabs) =>
        set({ membershipPlansTabs }),
      resetMembershipPlansTabs: () => set({ membershipPlansTabs: [] }),
    }),
    {
      name: 'manageMembershipPlansTabs',
      version: 1,
      getStorage: () => sessionStorage,
    },
  ),
);

export const useGetPlanDetail = (
  dentistInfoId?: number,
  getPlanDetailRequest?: GetPlanDetailRequest,
) =>
  useQuery({
    queryKey: ['MANAGE_PLAN_DETAIL', getPlanDetailRequest, dentistInfoId],
    queryFn: () =>
      getPlanDetail(dentistInfoId ? dentistInfoId : 0)
        .endpoint(getPlanDetailRequest, '')
        .then((res) => ({ ...res.data, targetType: 'location' as TargetType })),
    enabled: !!dentistInfoId && !!getPlanDetailRequest?.planId,
  });

export const useGetPlans = (dentistInfoId?: Id) =>
  useQuery({
    queryKey: `Plans: ${dentistInfoId}`,
    queryFn: () => getPlans.endpoint({}, dentistInfoId).then((res) => res.data),
    enabled: !!dentistInfoId,
  });

export const useChangePlansOrder = () =>
  useMutation<ChangePlansOrderResponse, unknown, ChangePlansOrderRequest>(
    ({ dentistInfoId, ...params }) =>
      changePlansOrder.endpoint(params, dentistInfoId).then((res) => res.data),
  );

export const useChangeMembershipSettingsSL = () =>
  useMutation<unknown, unknown, MembershipSettingsRequest>((params) =>
    changeMembershipSettingsSL.endpoint(params).then((res) => res.data),
  );

export const useUpdatePlanStatus = () =>
  useMutation<unknown, unknown, UpdatePlanStatusMultiRequest>((params) =>
    updatePlanStatus.endpoint(params).then((res) => res.data),
  );

export const useUpdatePlanPricing = (dentistInfoId: number) =>
  useMutation<
    UpdatePlanPricingMultiResponse,
    unknown,
    UpdatePlanPricingMultiRequest
  >((params) =>
    updatePlanPricing(dentistInfoId)
      .endpoint(params)
      .then((res) => res.data),
  );

export const useHandleRecommendedPricing = (dentistInfoId: string) =>
  useQuery({
    queryKey: `RECOMMENDED_PRICING ${dentistInfoId}`,
    queryFn: () => recommendedPricing.endpoint().then((res) => res.data),
  });

export const useClearDelinquency = () => useMutation(clearDelinquency.endpoint);

export const useClaimGuarantee = () => useMutation(claimGuarantee.endpoint);
