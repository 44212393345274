import React, { useEffect } from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import { useMemberTabsStore } from 'new/modules/MembersPage/hooks';
import { useSidebarStore } from 'new/hooks/useSidebarStore';
import useActiveOffice from 'new/hooks/useActiveOffice';
import { useManageLocationsTabsStore } from 'new/modules/ManageLocations/hooks';
import { useManageGroupsTabsStore } from 'new/modules/ManageGroups/hooks';
import { useManageUsersTabsStore } from 'new/modules/ManageUsers/hooks';
import { useMultiLocationBillingTabsStore } from 'new/modules/MultiLocationBilling/hooks';
import { useMembershipPlansTabsStore } from 'new/modules/MembershipPlansManage/hooks';
import { useManagePlansTabsStateStore } from 'new/modules/ManagePlans/hooks';

const ResetZustandStores: React.FC<WithRouterProps> = ({ location }) => {
  const { resetMemberTabs } = useMemberTabsStore();
  const { setViewAsStandard } = useSidebarStore();
  const { setViewAsLocationId } = useActiveOffice();
  const { resetManageLocationsTabs } = useManageLocationsTabsStore();
  const { resetManageGroupsTabs } = useManageGroupsTabsStore();
  const { resetManageUsersTabs } = useManageUsersTabsStore();
  const { resetManagePlansTabsState } = useManagePlansTabsStateStore();
  const { resetMembershipPlansTabs } = useMembershipPlansTabsStore();
  const { resetBillingTabs } = useMultiLocationBillingTabsStore();

  useEffect(() => {
    const isLogout = ['/accounts/login', '/admin/dentists'].includes(
      location.pathname,
    );

    if (isLogout) {
      resetMemberTabs();
      resetManageLocationsTabs();
      resetManageGroupsTabs();
      resetManageUsersTabs();
      resetManagePlansTabsState();
      resetMembershipPlansTabs();
      resetBillingTabs();
      setViewAsStandard(false);
      setViewAsLocationId(undefined);
    }
  }, [location.pathname]);

  return null;
};

export default withRouter(ResetZustandStores);
