import create, { GetState, SetState } from 'zustand';
import { persist, StoreApiWithPersist } from 'zustand/middleware';
import {
  AssignUserRequest,
  ManageUser,
  ManageUsersStore,
  RemoveUserAssignmentRequest,
} from 'new/modules/ManageUsers/types';
import { useMutation, useQuery } from 'react-query';
import {
  assignUsers,
  changeUserSettingsNew,
  geUsersAssignments,
  geUsersList,
  removeAssignment,
} from 'new/modules/ManageUsers/endpoints';
import { ChangeUserSettingsRequest } from 'new/modules/UserSettings/types';
import { Id } from 'new/api/types';

export const useManageUsersTabsStore = create<
  ManageUsersStore,
  SetState<ManageUsersStore>,
  GetState<ManageUsersStore>,
  StoreApiWithPersist<ManageUsersStore>
>(
  persist(
    (set) => ({
      manageUsersTabs: [] as ManageUser[],
      setManageUsersTabs: (manageUsersTabs) => set({ manageUsersTabs }),
      resetManageUsersTabs: () => set({ manageUsersTabs: [] }),
    }),
    {
      name: 'manageUsersTabs',
      version: 1,
      getStorage: () => sessionStorage,
    },
  ),
);

export const useGetUsersList = (userId?: number, callback?:any) =>
  useQuery({
    queryKey: ['MANAGE_USERS_LIST', userId],
    queryFn: () => geUsersList.endpoint({}, userId).then((res) => res.data),
    enabled: !!userId,
    
  });

export const useGetUserAssignments = (userId?: number) =>
  useQuery({
    queryKey: ['MANAGE_USERS_ASSIGNMENTS', userId],
    queryFn: () =>
      geUsersAssignments.endpoint({}, userId).then((res) => res.data),
    enabled: !!userId,
  });

export const useAssignUser = (userId?: number) =>
  useMutation<unknown, unknown, AssignUserRequest & { userId?: number }>(
    (params) =>
      assignUsers
        .endpoint(
          { locations: params.locations, groups: params.groups },
          userId || params.userId,
        )
        .then((res) => res.data),
  );

export const useRemoveAssignmentUser = (userId?: number) =>
  useMutation<unknown, unknown, RemoveUserAssignmentRequest>((params) =>
    removeAssignment.endpoint(params, userId).then((res) => res.data),
  );

export const useChangeUserSettingsNew = () =>
  useMutation<
    unknown,
    unknown,
    ChangeUserSettingsRequest & { id: Id; userId?: number }
  >(({ id, ...params }) => changeUserSettingsNew.endpoint(params, id));
