import { NamedRoute } from 'new/routing/types';

export type MarketingSpaceRouteName = 'MarketingSpace';

const BASE_URL = '/account/marketing';

export const marketingSpaceRoutes: NamedRoute[] = [
  {
    name: 'MarketingSpace',
    path: `${BASE_URL}`,
    getPath: (tab) => `${BASE_URL}${tab ? `?tab=${tab}` : ''}`,
  },
];
