import { useMutation, useQuery } from 'react-query';
import create, { GetState, SetState } from 'zustand';
import { persist, StoreApiWithPersist } from 'zustand/middleware';
import {
  BillingTabType,
  MultiLocationBillingTabsStore,
} from 'new/modules/MultiLocationBilling/types';
import {
  addPaymentMethod,
  getBillingList,
  getPaymentMethods,
  removeOrgPaymentMethod,
} from 'new/modules/MultiLocationBilling/endpoints';

export const useMultiLocationBillingTabsStore = create<
  MultiLocationBillingTabsStore,
  SetState<MultiLocationBillingTabsStore>,
  GetState<MultiLocationBillingTabsStore>,
  StoreApiWithPersist<MultiLocationBillingTabsStore>
>(
  persist(
    (set) => ({
      billingTabs: [] as BillingTabType[],
      setBillingTabs: (billingTabs) => set({ billingTabs }),
      resetBillingTabs: () => set({ billingTabs: [] }),
    }),
    {
      name: 'billingTabs',
      version: 1,
      getStorage: () => sessionStorage,
    },
  ),
);

export const useGetBillingList = (organizationId?: number) =>
  useQuery({
    queryKey: ['MULTI_LOCATION_BILLING_LIST', organizationId],
    queryFn: () => getBillingList.endpoint({}, organizationId).then((res) => res.data),
    enabled: !!organizationId,
  });

export const usePaymentMethodsList = (officeId?: number) =>
  useQuery({
    queryKey: ['PAYMENT_METHODS_LIST', officeId],
    queryFn: () =>
      getPaymentMethods
        .endpoint({ dentistInfoId: officeId as number })
        .then((res) => res.data),
    enabled: !!officeId,
  });

export const useAddPaymentMethod = () => useMutation(addPaymentMethod.endpoint);

export const useRemovePaymentMethod = () =>
  useMutation(removeOrgPaymentMethod.endpoint);
