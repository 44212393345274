import React, { useEffect } from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const ResetCookies: React.FC<WithRouterProps> = ({ location }) => {
  useEffect(() => {
    const isLogout = ['/accounts/login', '/admin/dentists'].includes(
      location.pathname,
    );

    if (isLogout) {
      cookies.remove('seasonOfGivingAcknowledged', { path: '/' });
    }
  }, [location.pathname]);

  return null;
};

export default withRouter(ResetCookies);
