import { take, call, put, select } from 'redux-saga/effects';
import { replace } from 'react-router-redux';

import {
  selectCurrentPath,
  selectNextPathname,
} from 'common/selectors/router.selector';
import { USER_TYPES } from 'common/constants';
import request from 'utils/request';
import { getItem, removeItem } from 'utils/localStorage';

import {
  selectCurrentUser,
  selectUserType,
  selectAssociations,
} from 'containers/App/selectors';

import {
  setAuthState,
  setUserData,
  fetchAssociations,
} from 'containers/App/actions';

import { ME_FROM_TOKEN } from 'containers/App/constants';

function* refreshAuthFlow() {
  while (true) {
    yield take(ME_FROM_TOKEN);
    yield call(loadUserFromToken);
  }
}

import Cookies from 'universal-cookie';
import findRoute from '../../new/routing/routes';
const cookies = new Cookies();

const startPageMap = {
  dashboard: findRoute('AnalyticDashboardPage').path,
  members: findRoute('MembersPage').path,
  reports: findRoute('Reports').path,
};

function* loadUserFromToken() {
  const officeId =
    cookies.get('officeId') != undefined
      ? '?dentistInfoId=' + cookies.get('officeId')
      : '';
  const requestURL = '/api/v1/users/me/account' + officeId;
  const user = yield select(selectCurrentUser);
  const currentPath = yield select(selectCurrentPath);
  const authToken = getItem('auth_token');
  let excludePages = ['/accounts/small-business-signup'];
  if (user || !authToken || excludePages.indexOf(currentPath) != -1) {
    return;
  }

  try {
    const response = yield call(request, requestURL);
    if (!response.err && !response.data.error) {
      Stripe.setPublishableKey(
        response.stripe_public_key || 'pk_test_s61eqBiXMm1JpZsQooE9altN',
      );
      yield put(setUserData(response.data));
      // redirect them if they should now be on the new offices
      if(response.data.tieredPayment!=undefined&&response.data.tieredPayment.tierLevel!=0&&window.location.href.indexOf('dentist-new')!=-1&&!cookies.get('beccascookie')){
        window.location.replace(response.data.login_page)
      }
    }
  } catch (e) {
    yield cookies.remove('officeId', { path: '/' });
    yield cookies.remove('beccascookie', { path: '/' });
    yield cookies.remove('termsYes', { path: '/' });
    yield put(setAuthState(false));
    yield put(setUserData(false));
    removeItem('redirectStore');

    // if returns forbidden we remove the token from local storage
    if (e.res && e.res.status === 401) {
      removeItem('auth_token');
    } else {
      console.error(e);
    }
  }
}

export default [refreshAuthFlow];
