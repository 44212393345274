import { TENANT } from 'new/tenants';
import { PartialRecord, TenantStruct } from 'new/types/util';

export const UCRCodesVHQ = [
  '92014',
  '92015',
  'S9986',
  '92310',
  '99213',
] as const;
export const UCRCodesDHQ = [
  '0120',
  '0145',
  '0150',
  '0210',
  '0220',
  '0272',
  '0274',
  '1110',
  '1120',
  '1208',
  '4910',
] as const;

export type UCRCode = typeof UCRCodesVHQ[number] | typeof UCRCodesDHQ[number];
export type CodesTypes = PartialRecord<UCRCode, string>;

export const UCRCodesIds: Record<UCRCode, number> = {
  '92014': 1,
  '92015': 2,
  S9986: 4,
  '92310': 5,
  '99213': 3,
  '0120': 1,
  '0145': 18,
  '0150': 3,
  '0220': 4,
  '0272': 5,
  '0274': 6,
  '1110': 8,
  '1120': 9,
  '1208': 10,
  '4910': 16,
  '0210': 19,
};

export const getUCRData = (
  data: Partial<CodesTypes>,
  code: UCRCode,
): string => {
  if (data[code] === undefined) {
    throw new Error("Couldn't find the UCR Value");
  }
  return data[code]?.toString() ?? ''; //TODO: this gives error on precommit hook for some reason, find a way to fix it
};

type UCRCodeValues = {
  value: UCRCode;
  label: string;
};

const MATCHED_CODES_VALUES_VHQ: UCRCodeValues[] = [
  { value: '92014', label: 'Annual Eye Exam' },
  { value: '92015', label: 'Refraction' },
  { value: 'S9986', label: 'Retinal Screening' },
  { value: '92310', label: 'Contact Lens Exam' },
  { value: '99213', label: 'Level 3 Medical Exam' },
];

const MATCHED_CODES_VALUES_DHQ: UCRCodeValues[] = [
  { value: '0120', label: 'Periodic Exam' },
  {
    value: '0145',
    label: 'Oral Exam under 3 years',
  },
  {
    value: '0150',
    label: 'New Patient Exam',
  },
  { value: '0210', label: 'Periapical X-Ray - Complete Series' },
  { value: '0220', label: 'Periapical (root) X-Ray' },
  { value: '0272', label: '2 Bitewing (tooth) X-Rays' },
  { value: '0274', label: '4 Bitewing (tooth) X-Rays' },
  { value: '1110', label: 'Basic Cleaning (adult)' },
  { value: '1120', label: 'Basic Cleaning (child)' },
  {
    value: '1208',
    label: 'Fluoride Treatment',
  },
  { value: '4910', label: 'Periodontal Cleaning' },
];

const matchedCodesTenants: TenantStruct<UCRCodeValues[]> = {
  vhq: MATCHED_CODES_VALUES_VHQ,
  dhq: MATCHED_CODES_VALUES_DHQ,
};

export const MATCHED_CODES_VALUES: UCRCodeValues[] =
  matchedCodesTenants[TENANT.id];
