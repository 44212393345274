import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, submit as submitForm } from 'redux-form';
import Row from 'react-bootstrap/lib/Row';
import { connect } from 'react-redux';

import LabeledInput from 'components/LabeledInput';
import formValidator from './validator';

const mapDispatchToProps = (dispatch) => ({
  submit: () => dispatch(submitForm('reportIssue')),
});


@connect(null, mapDispatchToProps)
@reduxForm({
  form: 'reportIssue',
  validate: formValidator
})
class InvitePatientForm extends React.Component {

  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  getLabeledInput (props) {
    return new LabeledInput(props);
  }

  submitFunc = (values) => this.props.onSubmit(values);

  render () {
    const { handleSubmit, submitting } = this.props;

    return (
      <form
        onSubmit={handleSubmit}
        className="form-horizontal"
        id="support-form"
      >
        <Row>
          <Field
            name="message"
            type="textarea"
            component={this.getLabeledInput}
            maxLength={500}
            rows={5}
            label="Summary of Issue"
            placeholder="Type..."
            disabled={submitting}
            className="col-sm-12"
          />
        </Row>
        <Row>
          <div className="col-md-offset-10 col-md-2">
            <button
              type="submit"
              disabled={submitting}
              className="btn btn-block btn-cyan btn-round pull-right"
            >
              Report
            </button>
          </div>
        </Row>

        <div className="clearfix" />
      </form>
    );
  }
}

export default InvitePatientForm;
