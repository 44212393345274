import { NamedRoute } from 'new/routing/types';

export type ManageGroupsRouteName = 'ManageGroups';

const MANAGE_GROUPS_BASE_URL = '/account/manage-groups';

export const manageGroupsRoutes: NamedRoute[] = [
  {
    name: 'ManageGroups',
    path: MANAGE_GROUPS_BASE_URL,
    getPath: (id) => `${MANAGE_GROUPS_BASE_URL}${id ? `?id=${id}` : ''}`,
  },
];
