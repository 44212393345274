import { NamedRoute } from '../../routing/types';

export type BillingRouteName = 'BillingPage';

export const billingRoutes: NamedRoute[] = [
  {
    name: 'BillingPage',
    path: '/account/settings/billing',
    permission: '_all',
  },
];
