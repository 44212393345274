import SeedPlansCustom from 'containers/dashboardUpdate/MembershipsPage/seedPlansCustom';
import { AgeGroup, PlanSeed } from './types';
import { TemplateRecommendedPricing } from './components/PlanDetails/types';

export const GPP_PERCENTAGE_PRICING = 15;

// TODO: move the constant from old platform to new platform and type it correctly
export const SEED_PLANS: PlanSeed[] = SeedPlansCustom.map((seed) => ({
  name: seed.name,
  description: seed.description,
  type: seed.type as 'custom' | 'month',
  showOnBC: seed.showOnBC || false,
  limit_type: seed.limit_type as 'over' | 'under',
  subscription_age_group: seed.subscription_age_group as AgeGroup,
  showOnEnrollmentAnnual: seed.showOnEnrollmentAnnual,
  showOnEnrollmentMonthly: seed.showOnEnrollmentMonthly,
  minAge: seed.minAge,
  maxAge: seed.maxAge,
  recommendedPricingFormula:
    seed.recommendedPricingFormula as TemplateRecommendedPricing,
  worryFree: seed.worryFree || {
    month: null,
    year: null,
  },
}));
