import enUS from '../../translations/enUS.json';
import esUS from '../../translations/esUS.json';
import vhqEnUS from '../../translations/vhq.enUS.json';
import vhqEsUS from '../../translations/vhq.esUS.json';
import { Locale, Locales } from './types';

export const defaultLocale: Locale = 'en-US';

export const locales: Locales = {
  dhq: {
    'en-US': {
      messages: enUS,
    },
    'es-US': {
      messages: esUS,
    },
  },
  vhq: {
    'en-US': {
      messages: vhqEnUS,
    },
    'es-US': {
      messages: vhqEsUS,
    },
  },
};
