/*
Account Security Form Modal Component
================================================================================
*/

/*
Import
------------------------------------------------------------
*/
// libs
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import Row from 'react-bootstrap/lib/Row';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import moment from 'moment';

// app
import Checkbox from 'components/Checkbox';
import LabeledInput from 'components/LabeledInput';
import LoadingSpinner from 'components/LoadingSpinner';
import { getItem } from '../../utils/localStorage';

// local
import styles from './styles.module.css';
import { TENANT } from 'new/tenants';

/*
Member Form Modal
================================================================================
*/
@CSSModules(styles,{allowMultiple:true})
export default class StatsModal extends React.Component {

  static propTypes = {

    // modal related - passed in
    show: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
  };
  /*
  Render
  ------------------------------------------------------------
  */

  render () {
    const {
      // modal related
      show,
      onCancel,
      stats,
      goToKPIs
    } = this.props;

    let monthlyPaymentStatsAUC=0;
    let percentStatsAUC=0;
    let percentStatslastWeek=0;
    let monthlyPaymentStatslastWeek=0;
    let membersByMonth=[];
    let totalAUC=0;
    let totallastWeek=0
      if(stats!=null){
      totalAUC=stats.activeUserCount
      totallastWeek=stats.membersLastWeek
      membersByMonth=stats.membersByMonth.sort((a, b) => {
        const nameA = a.month;
        const nameB = b.month;
        if (nameA < nameB) {
          return -1;
        }
        else if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    }




    return (
      <Modal
        backdrop={'static'}
        bsSize={'lg'}
        onHide={onCancel}
        show={show}
      >
        {/*
        Modal Header
        ------------------------------------------------------------
        */}
        <Modal.Header closeButton>
          <Modal.Title>Stats</Modal.Title>
          <a styleName="float-right" onClick={this.props.gotoKPIs}>KPIs</a>
        </Modal.Header>

        {/*
        Modal Body
        ------------------------------------------------------------
        */}
        <Modal.Body>
        {stats!=null && (
            <div styleName="metrics">
              <p styleName="metrics__entry">
                Total Dentist Offices:
                {' '}
                <strong styleName="metrics__value">{stats.dentistOfficeCount}</strong>
              </p>
              {/* <p styleName="metrics__entry">
                Total Activated Dentist Offices:
                {' '}
                <strong styleName="metrics__value">{stats.activatedDentistOfficeCount}</strong>
              </p> */}
              <p styleName="metrics__entry">
                Total Offices with Active Members:
                {' '}
                <strong styleName="metrics__value">{stats.officesWithMembers}</strong>
              </p>
              
              <p styleName="metrics__entry">
                Total Active Members:
                {' '}
                <strong styleName="metrics__value">{totalAUC}</strong><br/>&emsp;Percent Fee: {percentStatsAUC}<br/>&emsp;Monthly Fee: {monthlyPaymentStatsAUC}
              </p>
              <p styleName="metrics__entry">
                Total Onboarded Offices:
                {' '}
                <strong styleName="metrics__value">{stats.onBoardingCount}</strong>
              </p>
              <p styleName="metrics__entry">
                Members added this week:
                {' '}
                <strong styleName="metrics__value">{totallastWeek}</strong><br/>&emsp;Percent Fee: {percentStatslastWeek}<br/>&emsp;Monthly Fee: {monthlyPaymentStatslastWeek}
              </p>

              <p styleName="metrics__entry">
              {TENANT.shortName}Mgmt Fee last month:
                {' '}
                <strong styleName="metrics__value">${stats.feesLastMonth}</strong>
              </p>
              <p styleName="metrics__entry">
              {TENANT.shortName}Mgmt Fee YTD:
                {' '}
                <strong styleName="metrics__value">${stats.feesYTD}</strong>
              </p>
            </div>
          )}

        </Modal.Body>

        {/*
        Modal Footer
        ------------------------------------------------------------
        */}
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    );
  }
}
