import { NamedRoute } from 'new/routing/types';

export type MultiLocationBillingRouteName = 'MultiLocationBilling';

const MULTI_LOCATION_BILLING_BASE_URL = '/account/multi-location-billing';

export const multiLocationBillingRoutes: NamedRoute[] = [
  {
    name: 'MultiLocationBilling',
    path: MULTI_LOCATION_BILLING_BASE_URL,
    getPath: (id) =>
      `${MULTI_LOCATION_BILLING_BASE_URL}${id ? `?id=${id}` : ''}`,
  },
];
