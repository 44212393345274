import { NamedRoute } from '../../routing/types';

export type MultiLocationDashboardRouteName = 'MultiLocationDashboardPage';

export const multiLocationDashboardRoutes: NamedRoute[] = [
  {
    name: 'MultiLocationDashboardPage',
    path: '/account/multi-location-dashboard',
  },
];
