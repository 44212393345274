
export const ME_FROM_TOKEN = 'App/ME_FROM_TOKEN';
export const SET_AUTH_STATE = 'App/SET_AUTH_STATE';
export const SET_USER_DATA = 'App/SET_USER_DATA';

export const SERVICES_REQUEST = 'App/SERVICES_REQUEST';
export const SERVICES_REQUEST_ATTEMPT = 'App/SERVICES_REQUEST_ATTEMPT';
export const SERVICES_REQUEST_SUCCESS = 'App/SERVICES_REQUEST_SUCCESS';
export const SERVICES_REQUEST_ERROR = 'App/SERVICES_REQUEST_ERROR';

export const FETCH_ASSOCIATIONS_REQUEST = 'App/FETCH_ASSOCIATIONS_REQUEST';
export const FETCH_ASSOCIATIONS_ATTEMPT = 'App/FETCH_ASSOCIATIONS_ATTEMPT';
export const FETCH_ASSOCIATIONS_SUCCESS = 'App/FETCH_ASSOCIATIONS_SUCCESS';
export const FETCH_ASSOCIATIONS_ERROR = 'App/FETCH_ASSOCIATIONS_ERROR';

export const CHANGE_PAGE_TITLE = 'App/CHANGE_PAGE_TITLE';

export const REVIEW_ADDED = 'App/REVIEW_ADDED';
export const REVIEW_EDITED = 'App/REVIEW_EDITED';

export const FETCH_PLAID_LINK_TOKEN = 'App/FETCH_PLAID_LINK_TOKEN';
export const FETCH_PLAID_LINK_TOKEN_SUCCESS= 'App/FETCH_PLAID_LINK_TOKEN_SUCCESS';

export const DENTIST_SPECIALTIES_REQUEST = 'App/DENTIST_SPECIALTIES_REQUEST';
export const DENTIST_SPECIALTIES_ATTEMPT = 'App/DENTIST_SPECIALTIES_ATTEMPT';
export const DENTIST_SPECIALTIES_SUCCESS = 'App/DENTIST_SPECIALTIES_SUCCESS';

export const PRICING_CODES_REQUEST = 'App/PRICING_CODES_REQUEST';
export const PRICING_CODES_ATTEMPT = 'App/PRICING_CODES_ATTEMPT';
export const PRICING_CODES_SUCCESS = 'App/PRICING_CODES_SUCCESS';


export const LEAD_TO_SF_REQUEST = 'App/LEAD_TO_SF_REQUEST';
export const LEAD_TO_SF_SUCCESS = 'App/LEAD_TO_SF_SUCCESS';

export const TERMS_OFF = 'App/TERMS_OFF'

export const RETRY_PAYMENT = 'App/RETRY_PAYMENT'

export const ENVELOPE_ID= 'APP/ENVELOPE_ID';

export const TURN_ON_UPGRADE_REQUEST='APP/TURN_ON_UPGRADE_REQUEST'

export const ADD_LOCATION_RESPONSE = 'APP/ADD_LOCATION_RESPONSE'

export const TOGGLE_SMS_OFFICE = 'APP/TOGGLE_SMS_OFFICE';
export const ANNOUNCEMENT_OFF = 'APP/ANNOUNCEMENT_OFF'
