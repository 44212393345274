import React from 'react';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';
import { TENANT } from 'new/tenants';
import vhq from './vhq';
import dhq from './dhq';
import { TenantStruct } from 'new/types/util';

const theme: TenantStruct<ThemeOptions> = {
  vhq,
  dhq,
};

const ThemeProvider: React.FC = ({ children }) => (
  <MuiThemeProvider theme={createTheme(theme[TENANT.id])}>
    {children}
  </MuiThemeProvider>
);

export default ThemeProvider;
