import {
  ME_FROM_TOKEN,
  SET_AUTH_STATE,
  SET_USER_DATA,

  ADD_LOCATION_RESPONSE,

  SERVICES_REQUEST,
  SERVICES_REQUEST_SUCCESS,
  SERVICES_REQUEST_ERROR,

  CHANGE_PAGE_TITLE,
  REVIEW_ADDED,
  REVIEW_EDITED,
  
  FETCH_PLAID_LINK_TOKEN,
  FETCH_PLAID_LINK_TOKEN_SUCCESS,

  LEAD_TO_SF_REQUEST,
  LEAD_TO_SF_SUCCESS,

  DENTIST_SPECIALTIES_REQUEST,
  DENTIST_SPECIALTIES_SUCCESS,

  PRICING_CODES_REQUEST,
  PRICING_CODES_SUCCESS,

  TERMS_OFF,
  RETRY_PAYMENT,
  FETCH_ASSOCIATIONS_REQUEST,
  FETCH_ASSOCIATIONS_SUCCESS,
  TURN_ON_UPGRADE_REQUEST,
  ENVELOPE_ID,

  TOGGLE_SMS_OFFICE,
  ANNOUNCEMENT_OFF

} from './constants';

export const UPDATE_LAYOUT = '(LAYOUT) UPDATE_THEME_LAYOUT';
export const TOGGLE_SIDENAV = '(LAYOUT) TOGGLE_SIDENAV';
export const SET_SIDENAV_OPEN = '(LAYOUT) SET_SIDENAV_OPEN';
export const TOGGLE_SIDENAV_VARIANT = '(LAYOUT) TOGGLE_SIDENAV_VARIANT';
export const TOGGLE_NOTIFICATIONS = '(LAYOUT) TOGGLE_NOTIFICATIONS';
export const TOGGLE_SEARCHING = '(LAYOUT) TOGGLE_SEARCHING';


export function meFromToken () {
  return {
    type: ME_FROM_TOKEN,
  };
}


export function setUserData (currentUser) {
  return {
    type: SET_USER_DATA,
    payload: {
      currentUser,
    },
  };
}


export function setAuthState (newAuthState) {
  return {
    type: SET_AUTH_STATE,
    payload: {
      newAuthState,
    },
  };
}


export function requestServices (payload) {
  return {
    type: SERVICES_REQUEST,
    payload
  };
}


export function requestServicesSuccess (payload) {
  return {
    type: SERVICES_REQUEST_SUCCESS,
    payload
  };
}


export function requestServicesError (payload) {
  return {
    type: SERVICES_REQUEST_ERROR,
    payload
  };
}


export function changePageTitle (payload) {
  return {
    type: CHANGE_PAGE_TITLE,
    payload,
  };
}

export function reviewAdded (payload) {
  return {
    type: REVIEW_ADDED,
    payload
  };
}

export function reviewEdited (payload) {
  return {
    type: REVIEW_EDITED,
    payload
  };
}


export const updateLayout = data => ({
  type: UPDATE_LAYOUT,
  payload: data
});

export const toggleSidenav = () => ({
  type: TOGGLE_SIDENAV
});

export const setSidenavOpen = data => ({
  type: SET_SIDENAV_OPEN,
  payload: data
});

export const toggleSidenavVariant = data => ({
  type: TOGGLE_SIDENAV_VARIANT,
  payload: data
});

export const toggleNotifications = () => ({
  type: TOGGLE_NOTIFICATIONS
});

export const toggleSearching= (toggle)=>({
  type: TOGGLE_SEARCHING,
  toggle
})

export const submitSFLeadForm =(payload)=>({
  type: LEAD_TO_SF_REQUEST,
  payload
});

export function submitSFLeadFormSuccess (payload){
  return {
    type: LEAD_TO_SF_SUCCESS,
    payload
  }
}

export const fetchPlaidLinkToken =()=>({
  type: FETCH_PLAID_LINK_TOKEN
});

export function fetchPlaidLinkTokenSuccess (payload){
  return {
    type: FETCH_PLAID_LINK_TOKEN_SUCCESS,
    payload
  }
}

export function dentistSpecialtiesRequest (payload) {
  return {
    type: DENTIST_SPECIALTIES_REQUEST,
    payload
  };
}

export function dentistSpecialtiesSuccess (payload) {
  return {
    type: DENTIST_SPECIALTIES_SUCCESS,
    payload
  };
}

export function pricingCodesRequest (payload) {
  return {
    type: PRICING_CODES_REQUEST,
    payload
  };
}

export function pricingCodesSuccess (payload) {
  return {
    type: PRICING_CODES_SUCCESS,
    payload
  };
}

export function turnOffTerms(dentistInfoId,dentist_type) {
  return {
    type: TERMS_OFF,
    dentistInfoId,
    dentist_type
  };
}

export function turnOffAnnouncement(userId) {
  return {
    type: ANNOUNCEMENT_OFF,
    userId
  };
}

export function retryPayment(userId){
  return {
    type: RETRY_PAYMENT,
    userId
  };
}

// Associations
export function fetchAssociations () {
  return {
    type: FETCH_ASSOCIATIONS_REQUEST,
  };
}

export function fetchAssociationsSuccess (payload) {
  return {
    type: FETCH_ASSOCIATIONS_SUCCESS,
    payload
  };
}


export function updateEnvelopeId(which,dentistInfoId,envelopeId) {
  return {
    type: ENVELOPE_ID,
    which,
    dentistInfoId,
    envelopeId
  }
}

export function turnOnUpgradeRequest(dentistInfoId,toast){
  return {
    type: TURN_ON_UPGRADE_REQUEST,
    dentistInfoId,
    toast
  };
}

export function setAddLocationResponse (payload){
  return {
    type: ADD_LOCATION_RESPONSE,
    payload
  }
}


export function setAllowSMSAction(dentistInfoId) {
  return {
      type: TOGGLE_SMS_OFFICE,
      dentistInfoId
  };
}
