import { NamedRoute } from 'new/routing/types';

export type ManageUsersRouteName = 'ManageUsers';

const MANAGE_USERS_BASE_URL = `/account/manage-users`;

export const manageUsersRoutes: NamedRoute[] = [
  {
    name: 'ManageUsers',
    path: `/account/manage-users`,
    getPath: (id) => `${MANAGE_USERS_BASE_URL}${id ? `?id=${id}` : ''}`,
  },
];
