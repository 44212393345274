
export function setItem (...args) {
  localStorage.setItem(...args);
}

export function getItem (...args) {
  return localStorage.getItem(...args);
}

export function removeItem (...args) {
  localStorage.removeItem(...args);
}
