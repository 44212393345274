import { NamedRoute } from '../../routing/types';

export type TestPlaygroundRouteName = 'TestPlaygroundPage' | 'TestLayoutPage';

export const testPlaygroundRoutes: NamedRoute[] = [
  {
    name: 'TestPlaygroundPage',
    path: '/test-playground',
    permission: '_all',
    getPath: (tab) => `/test-playground${tab ? `?tab=${tab}` : ''}`,
  },
  {
    name: 'TestLayoutPage',
    path: '/test-layout',
    permission: '_all',
  },
];
