import { ResponseType } from 'axios';
import { filetypeHeaderMap } from 'new/modules/Reports/endpoints';
import { FileTypes } from 'new/modules/Reports/types';

export const downloadFileFromResponse = (
  data: Blob,
  fileType: FileTypes,
  fileName?: string,
) => {
  const downloadUrl = window.URL.createObjectURL(
    new Blob([data], {
      type: filetypeHeaderMap[fileType],
    }),
  );

  if (fileType === 'pdf') {
    window.open(downloadUrl, '_blank');
  } else {
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `${fileName || 'file'}.${fileType}`);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(downloadUrl);
  }
};

export const getRequestConfigForFileDownload = (fileType: FileTypes) => ({
  headers: { accept: filetypeHeaderMap[fileType] },
  responseType: 'blob' as ResponseType,
});
