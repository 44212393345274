import { NamedRoute } from 'new/routing/types';

export type PatientReferralRouteName = 'PatientReferralPage';
export type OfficeReferralRouteName = 'OfficeReferralPage';
export type LearnMoreRouteName = 'LearnMorePage';

const REFERRAL_PATIENT_BASE_URL = '/patient-referral/:paymentUniqueId';
const REFERRAL_OFFICE_BASE_URL = '/office-referral/:paymentUniqueId';
const REFERRAL_OFFICE_FORM = '/learn-more';

export const referralRoutes: NamedRoute[] = [
  {
    name: 'PatientReferralPage',
    path: REFERRAL_PATIENT_BASE_URL,
    getPath: () => REFERRAL_PATIENT_BASE_URL,
  },
  {
    name: 'OfficeReferralPage',
    path: REFERRAL_OFFICE_BASE_URL,
    getPath: () => REFERRAL_OFFICE_BASE_URL,
  },
  {
    name: 'LearnMorePage',
    path: REFERRAL_OFFICE_FORM,
    getPath: () => REFERRAL_OFFICE_FORM,
  },
];
