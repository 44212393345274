import { NamedRoute } from 'new/routing/types';

export type OrderInfoCardsRouteName = 'OrderInfoCards';

const ORDER_INFO_CARDS_BASE_URL = '/account/order-info-cards';

export const orderInfoCardsRoutes: NamedRoute[] = [
  {
    name: 'OrderInfoCards',
    path: ORDER_INFO_CARDS_BASE_URL,
    getPath: (id) =>
      `${ORDER_INFO_CARDS_BASE_URL}${id ? `?id=${id}` : ''}`,
  },
];
