import { NamedRoute } from '../../routing/types';

export type CommunityConnectRouteName = 'CommunityConnectPage';

export const communityConnectRoutes: NamedRoute[] = [
  {
    name: 'CommunityConnectPage',
    path: '/account/community-connect',
    permission: '_all',
  },
];
