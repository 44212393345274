import { NamedRoute } from '../../routing/types';

export type SeasonOfGivingRoutesName = 'SeasonOfGivingPage';

const SEASON_OF_GIVING_BASE_URL = '/leaderboard';

export const seasonOfGivingRoutes: NamedRoute[] = [
  {
    name: 'SeasonOfGivingPage',
    path: `${SEASON_OF_GIVING_BASE_URL}`,
    getPath: () => SEASON_OF_GIVING_BASE_URL,
  },
];
