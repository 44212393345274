import { NamedRoute } from 'new/routing/types';

export type ManageLocationsRouteName =
  | 'ManageLocations'
  | 'ManageLocationsEdit';

const MANAGE_LOCATIONS_BASE_URL = '/account/manage-locations';
const MANAGE_LOCATIONS_EDIT_BASE_URL = '/account/manage-locations/edit';

export const manageLocationsRoutes: NamedRoute[] = [
  {
    name: 'ManageLocations',
    path: MANAGE_LOCATIONS_BASE_URL,
    getPath: (id) => `${MANAGE_LOCATIONS_BASE_URL}${id ? `?id=${id}` : ''}`,
  },
  {
    name: 'ManageLocationsEdit',
    path: MANAGE_LOCATIONS_EDIT_BASE_URL,
    getPath: (id) =>
      `${MANAGE_LOCATIONS_EDIT_BASE_URL}${id ? `?id=${id}` : ''}`,
  },
];
