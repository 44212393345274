import { useQuery, useMutation } from 'react-query';
import create, { GetState, SetState } from 'zustand';
import { persist, StoreApiWithPersist } from 'zustand/middleware';
import {
  getLocationGroups,
  getGroupDetails,
  removeGroup,
  updateGroupName,
} from 'new/modules/ManageGroups/endpoints';
import {
  ManageGroup,
  ManageGroupsStore,
  RemoveGroupRequest,
  GroupName,
  ManageGroupTab,
} from 'new/modules/ManageGroups/types';

export const useGetLocationGroups = (organizationId?: number) =>
  useQuery<unknown, unknown, ManageGroup[]>({
    queryKey: ['LOCATION_GROUPS', organizationId],
    queryFn: () =>
      getLocationGroups
        .endpoint({ organizationId: organizationId as number })
        .then((res) => res.data),
    enabled: !!organizationId,
  });

export const useManageGroupsTabsStore = create<
  ManageGroupsStore,
  SetState<ManageGroupsStore>,
  GetState<ManageGroupsStore>,
  StoreApiWithPersist<ManageGroupsStore>
>(
  persist(
    (set) => ({
      manageGroups: [] as ManageGroupTab[],
      setManageGroupsTabs: (manageGroups) => set({ manageGroups }),
      resetManageGroupsTabs: () => set({ manageGroups: [] }),
    }),
    {
      name: 'manageGroupsTabs',
      version: 1,
      getStorage: () => sessionStorage,
    },
  ),
);

export const useGetGroupDetails = (dentistInfoId: string, groupId: string) =>
  useQuery({
    queryKey: `MANAGE GROUP DETAILS: ${groupId}`,
    queryFn: () =>
      getGroupDetails
        .endpoint({ userId: dentistInfoId }, groupId)
        .then((res) => res.data),
    enabled: !!groupId,
  });

export const useRemoveGroup = () =>
  useMutation<unknown, unknown, RemoveGroupRequest>((groupId) =>
    removeGroup.endpoint({}, groupId).then((res) => res.data),
  );

export const useUpdateGroupName = (groupId?: string) =>
  useMutation<unknown, unknown, GroupName>((params) =>
    updateGroupName.endpoint(params, groupId),
  );
