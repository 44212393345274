import { NamedRoute } from 'new/routing/types';

export type MultiLocationReportsRouteName = 'MultiLocationReports';

const MULTI_LOCATION_REPORTS_BASE_URL = '/account/multi-location-reports';

export const multiLocationReportsRoutes: NamedRoute[] = [
  {
    name: 'MultiLocationReports',
    path: MULTI_LOCATION_REPORTS_BASE_URL,
    getPath: (id?) =>
      `${MULTI_LOCATION_REPORTS_BASE_URL}${id ? `?tab=${id}` : ''}`,
  },
];
