import { NamedRoute } from '../../routing/types';

export type SetupRouteNames =
  | 'SingleSetupPage'
  | 'MultiSetupPage'
  | 'MultiSetupCSVReview';

export const setupRoutes: NamedRoute[] = [
  {
    name: 'SingleSetupPage',
    path: '/single-setup',
    permission: '_all',
  },
  {
    name: 'MultiSetupPage',
    path: '/multi-setup',
    permission: '_all',
  },
  {
    name: 'MultiSetupCSVReview',
    path: '/multi-csv-review',
    permission: '_all',
  },
];
