import React from 'react';
import { ToastContainer as DefaultToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { styled } from '@mui/material';

const StyledToastWrapper = styled('div')(({ theme }) => ({
  '.Toastify__toast--success': {
    svg: {
      fill: theme.palette.success.main,
    },
    '.Toastify__progress-bar': {
      background: theme.palette.success.main,
    },
  },

  '.Toastify__toast--warning': {
    svg: {
      fill: theme.palette.warning.main,
    },
    '.Toastify__progress-bar': {
      background: theme.palette.warning.main,
    },
  },

  '.Toastify__toast--error': {
    svg: {
      fill: theme.palette.error.main,
    },
    '.Toastify__progress-bar': {
      background: theme.palette.error.main,
    },
  },

  '.Toastify__toast--info': {
    svg: {
      fill: theme.palette.primary.main,
    },
    '.Toastify__progress-bar': {
      background: theme.palette.primary.main,
    },
  },
}));

const ToastContainer: React.FC = () => {
  return (
    <StyledToastWrapper>
      <DefaultToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        transition={Slide}
      />
    </StyledToastWrapper>
  );
};

export default ToastContainer;
