/*
Account Security Form Modal Component
================================================================================
*/

/*
Import
------------------------------------------------------------
*/
// libs
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import Row from 'react-bootstrap/lib/Row';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import moment from 'moment';
import Countdown from 'components/Countdown';

// app
import Checkbox from 'components/Checkbox';
import LabeledInput from 'components/LabeledInput';
import LoadingSpinner from 'components/LoadingSpinner';
import { getItem } from '../../utils/localStorage';

// local
import styles from './styles.module.css';

var myVar;
/*
Member Form Modal
================================================================================
*/
@CSSModules(styles,{allowMultiple:true})
export default class TimeoutModal extends React.Component {

  static propTypes = {

    // modal related - passed in
    show: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
  };


  /*
  Render
  ------------------------------------------------------------
  */

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.show==true&&this.props.show==false){
      myVar=setTimeout(() => {
        this.props.onExpire();
      }, 300000); //300000
    }
  }

  onStopTimeout=()=>{
    clearTimeout(myVar);
    this.props.onCancel();
  }

  onLogout=()=>{
    clearTimeout(myVar);
    this.props.onExpire();
  }

  render () {
    const {
      // modal related
      show,
      onCancel,
      stats,
    } = this.props;


    return (
      <Modal
        backdrop={'static'}
        bsSize={'sm'}
        onHide={this.onStopTimeout}
        show={show}
      >
        {/*
        Modal Header
        ------------------------------------------------------------
        */}
        <Modal.Header closeButton>
          <Modal.Title>All done for now?</Modal.Title>
        </Modal.Header>

        {/*
        Modal Body
        ------------------------------------------------------------
        */}
        <Modal.Body>
            <p>Due to inactivity, and to ensure the security of your account, you will be automatically logged out in 5 minutes.</p>
            <p>Click "Continue Session" to...continue your session. Otherwise, log out and we'll see you next time.</p>
            <p styleName="timeleft">Time left:&nbsp;
              <Countdown
                insertedMinutes={5}
              />
            </p>
        </Modal.Body>

        {/*
        Modal Footer
        ------------------------------------------------------------
        */}
        <Modal.Footer>
        <div className="text-center">
            <input className="btn btn-danger btn-primary" type="button" onClick={this.onLogout} value="Log out" />{' '}
            <input className="btn btn-primary" type="button" onClick={this.onStopTimeout} value="Continue Session" />
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}
