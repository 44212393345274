import { NamedRoute } from '../../routing/types';

export type AnalyticDashboardRouteName = 'AnalyticDashboardPage';

export const analyticDashboardRoutes: NamedRoute[] = [
  {
    name: 'AnalyticDashboardPage',
    path: '/account/dashboard',
    permission: '_all',
  },
];
