/*
Footer Component
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
// libs
import React, {Component} from 'react';
import CSSModules from 'react-css-modules';
import FaFacebook from 'react-icons/lib/fa/facebook-square';
import FaInstagram from 'react-icons/lib/fa/instagram';
import FaTwitter from 'react-icons/lib/fa/twitter-square';
import FaLinkedIn from 'react-icons/lib/fa/linkedin-square';
import FaYouTube from 'react-icons/lib/fa/youtube-square';
import { Link } from 'react-router';


// app
import SupportModal from 'components/SupportModal';
import { TENANT } from 'new/tenants'
const { pngLogo } = TENANT.logos;

// local
import styles from './styles.module.css';


/*
Footer
================================================================================
*/
class Footer extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showSupportModal: false,
    };
  }

  openSupportModal = (evt) => {
    evt.preventDefault();
    this.setState({
      showSupportModal: true,
    });
  };

  closeSupportModal = () => {
    this.setState({
      showSupportModal: false,
    });
  };


  render () {
    return (
      <div styleName={this.props.pathname.indexOf('dentist-new')!=-1 || this.props.pathname.indexOf('business')!=-1
 ?"footer":"footer-different"}>

      <div className="col-md-3">
         <div styleName="powered-by">
          Powered By
          <img src={pngLogo} alt={TENANT.name} styleName="powered-by--logo"/>
        </div>
      </div>

        <div className="col-md-9">
          <ul styleName="footer__nav">
            {this.props.pathname.indexOf('/business/')==-1&&this.props.pathname.indexOf('/small-business')==-1?(
            <li>
              <a target="_blank" href={this.props.pathname.indexOf('/patient/')!=-1||this.props.pathname.indexOf('/my-optometrist/')!=-1||this.props.pathname.indexOf('/my-dentist/')!=-1?"/faq":(this.props.pathname.indexOf('dentist-new')!=-1?"/dentist-new/faq":"/dentist/faq")}>FAQ</a>
            </li>
            ):(
              <li>
              <a target="_blank" href="/business-faq">FAQ</a>
            </li>
            )}
            {this.props.pathname.indexOf('small-business')!=-1?(
              <li>
              <a href="/business-connect-terms" target="_blank">Terms and Conditions</a>
              </li>
            ):(
            <li>
              <a href="/terms" target="_blank">Terms and Conditions</a>
            </li>
            )}
            <li>
              <Link href="/privacy" target="_blank">Privacy Policy</Link>
            </li>
            {/* <li>
              <a href="" onClick={this.openSupportModal}>Report Issue</a>
            </li> */}
          </ul>
        </div>


        {this.props.showDisclaimer && (
          <p styleName="disclaimer">
            ***{TENANT.nameSimple.toLowerCase()} Membership Plans are NOT {TENANT.nameSimple.toUpperCase()} INSURANCE. A {TENANT.nameSimple.toLowerCase()} Membership Plan
            is a package of services that the {TENANT.nameSimple.toLowerCase()} office offers for the
            prices listed. The {TENANT.nameSimple.toLowerCase()} Membership Plans on the {TENANT.name} directory
            are between you and your selected dentist, and are active
            immediately upon payment with no waiting period. Payments made using
            the {TENANT.name} platform go directly to the {TENANT.nameSimple.toLowerCase()} office. You may
            cancel your {TENANT.nameSimple.toLowerCase()} Membership Plan for any reason after 90 days. You
            may cancel your {TENANT.nameSimple.toLowerCase()} Membership Plan for a full refund before 90
            days by contacting your {TENANT.professionalName}, however, payment in full for any
            services utilized must be made directly to your {TENANT.professionalName} before your
            {TENANT.nameSimple.toLowerCase()} Membership Plan can be canceled.***
          </p>
        )}
        <SupportModal
          show={this.state.showSupportModal}
          onHide={this.closeSupportModal}
        />
      </div>
    );
  }
}

export default CSSModules(styles, { allowMultiple: true })(Footer);
