/*
Dentist Members Page Selectors
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
// lib
import get from 'lodash/get';
import { createSelector } from 'reselect';
import moment from 'moment';

// app
import { selectCurrentUser } from 'containers/App/selectors';

/*
Selectors
================================================================================
*/
const domainSelector = state => state.dentistMembersPage;

/*
Search / Filter Patients
------------------------------------------------------------
*/
const selectMemberSearchTerm = createSelector(
  domainSelector,
  (substate) => substate.searchName
);

const selectMemberFilterTerm = createSelector(
  domainSelector,
  (substate) => substate.filterStatus
);

const selectDentistReports = createSelector(
  domainSelector,
  (substate) => substate.dentistReports
);

const selectDentistWebReport = createSelector(
  domainSelector,
  (substate) => substate.dentistReportData
);

const selectDentistPayouts = createSelector(
  domainSelector,
  (substate) => substate.dentistPayouts
);

const selectDentistWebPayout = createSelector(
  domainSelector,
  (substate) => substate.dentistPayoutData
);

/*
Fetch
------------------------------------------------------------
*/
const selectDentistInfo = createSelector(
  domainSelector,
  (substate) => {
    let { dentistInfo } = substate;
    return dentistInfo
  }
);

const selectEnvelopeId = createSelector(
  domainSelector,
  (substate) => {
    let { envelopeId } = substate;
    return envelopeId
  }
);

const selectStats = createSelector(
  domainSelector,
  (substate) => {
    let { stats } = substate;
    return stats
  }
);

const selectTotalRevenue = createSelector(
  domainSelector,
  (substate) => substate.totalRevenue
);

const selectActiveMemberCount = createSelector(
  domainSelector,
  (substate) => substate.activeMemberCount
);

const selectMonthlyMembers = createSelector(
  domainSelector,
  (substate) => substate.monthlyMembers
);

const selectAnnualMembers = createSelector(
  domainSelector,
  (substate) => substate.annualMembers
);

const selectDentistUsers = createSelector(
  domainSelector,
  (substate) => {
    let { dentistUsers } = substate;
    return dentistUsers
  }
);

const selectPatients = createSelector(
  domainSelector,
  (substate) => substate.patients
);

const selectPatientsIds = createSelector(
  domainSelector,
  (substate) => substate.patientIds
);

const selectOtherPatientIds = createSelector(
  domainSelector,
  (substate) => substate.otherPatientIds
);

const selectOtherPatients = createSelector(
  domainSelector,
  (substate) => substate.otherPatients
);

const selectProcessedPatients = createSelector(
  selectPatients,
  selectPatientsIds,
  selectMemberSearchTerm,
  selectMemberFilterTerm,
  (patients, patientIds,searchName, filterStatus) => {
    // precondition: patients are null
    if (patients === null) {
      return patients;
    }
    let processedPatients = patientIds;

    // if(searchName==null&&filterStatus === "all"){
    //   return patientIds
    // }

    for (let i = 0; i < patients.length; i++) {
        // if (searchName !== null) {
        //   searchName = searchName.toString().toLowerCase();
        //   let member=patients[i]
        //   let memberInfo=member.firstName+' '+member.lastName+' '+member.firstName+' '+member.email;
        //   patients[i].members!=undefined&&patients[i].members.length>0&&patients[i].members.map(mem=>{
        //     memberInfo+=mem.firstName+' '+mem.lastName+' '+mem.firstName;
        //   })
        //   if(memberInfo.toLowerCase().indexOf(searchName)!=-1) processedPatients.push(member.id)
        // }else 
        if(filterStatus!=='all'&&patients[i].members!=undefined){
          let member=patients[i]
           const statuses =  [...patients[i].members, member]
              .map((member) => {
                let status = member.clientSub!=undefined
                  ? member.clientSub.status
                  : 'inactive';

                if (status === 'cancellation_requested') {
                  status = 'active';
                }
                return status;
              });

          const addedDates = [...patients[i].members, member]
            .map((member) => {
            let addedDate=member.clientSub
            ? member.clientSub.createdAt
            : '2000-01-01 00:00:00';
            return addedDate;
          });
          const recurringDates =[...patients[i].members, member]
            .map((member) => {
            let addedDate=member.clientSub &&member.clientSub.membership&&member.clientSub.subscriptionCreationDate!=null
            ? member.clientSub.subscriptionCreationDate
            : null;
            return addedDate;
          });

          if (filterStatus ==='new'&& addedDates.every((addedDate) => {
            return moment().diff(moment(addedDate), 'days')<31;
          })){
            processedPatients.push(member.id)
          }else if (filterStatus ==='uncancel'&& addedDates.every((addedDate) => {
              return moment().diff(moment(addedDate), 'days')<91;
          })){
            processedPatients.push(member.id)
          }else if (filterStatus ==='cash' &&statuses.some((status) => {
            return status === 'active_cash';
          })){
            processedPatients.push(member.id)
        }else if (filterStatus ==='transferred'&& statuses.some((status) => {
            return status === 'active_transferred'||status==='inactive_transferred';
          })){
            processedPatients.push(member.id)
        }else if (filterStatus ==='not_cash'&&statuses.every((status) => {
            return status !== 'active_cash' && status!=='pending_cash'&&status !== 'inactive_transferred' && status!=='active_transferred';
          })){
            processedPatients.push(member.id)
        }else if (filterStatus ==='disputed'&& statuses.some((status) => {
              return status === filterStatus;
            })){
              processedPatients.push(member.id)
            }else if (filterStatus ==='renewal'&& recurringDates.some((addedDate) => {
                            return (moment().diff(moment(addedDate), 'days')%365)>335 && (statuses.indexOf('active')!=-1||statuses.indexOf('active_cash')!=-1||statuses.indexOf('active_transferred')!=-1);
            })){
              processedPatients.push(member.id)
        }else if (filterStatus === 'inactive'&& statuses.some((status) => {
            return status=='canceled'||status=='pending_cash'||status=='delinquent'||status=='inactive_transferred';
          })){
            processedPatients.push(member.id)

        }else if (filterStatus === 'active' && statuses.some((status) => {
            return status === 'active'||status=='cancellation_requested'||status=='active_cash'||status=='past_due'||status=='active_transferred';
          })){
            processedPatients.push(member.id)
        } else if(statuses.some((status) => {
            return status === filterStatus;
          })){
            processedPatients.push(member.id)
          }

      }
    }

    // if(filterStatus=='delinquent'){
    //   // sort by date hit delinquent desc
    //   processedPatients = processedPatients.sort((patientA, patientB) => {
    //     if (patientA.updatedAt > patientB.updatedAt) {
    //       return -1;
    //     }
    //     else if (patientA.updatedAt < patientB.updatedAt) {
    //       return 1;
    //     }
    //     return 0;
    //   });
    // }else{
    //   // sort by name to organize the result
    // }


    return processedPatients;
  }
);

const selectProcessedOtherPatients = createSelector(
  selectOtherPatients,
  selectOtherPatientIds,
  selectMemberSearchTerm,
  selectMemberFilterTerm,
  (patients, patientIds,searchName, filterStatus) => {

    // precondition: patients are null
    if (patients === null) {
      return patients=[];
    }

    if(searchName==null){
      return [];
    }
    let processedPatients = []
    for (let i = 0; i < patients.length; i++) {
        if (searchName !== null) {
          searchName = searchName.toString().toLowerCase();
          let member=patients[i]
          let memberInfo=member.firstName+' '+member.lastName+' '+member.firstName+' '+member.email;
          //if(patients[i].members.length>0)
          if(memberInfo.toLowerCase().indexOf(searchName)!=-1) processedPatients.push(member.id)
      }
    }

    return processedPatients;
  }
);

const selectDataLoaded = createSelector(
  selectCurrentUser,
  selectDentistInfo,
  //selectPatients,
  selectStats,
  (user, dentistInfo, stats) => {
    return user !== false && dentistInfo !== null  &&stats!=null;
  }
);

/*
Add / Edit
------------------------------------------------------------
*/
const selectEditingMember = createSelector(
  domainSelector,
  (substate) => {
    if (substate.editingActive === 'member') {
      return substate.editing;
    }

    return null;
  }
);

const selectEditingPatientProfile = createSelector(
  domainSelector,
  (substate) => {

    if (substate.editingActive === 'patientProfile') {
      return {...substate.editing,spanish:substate.editing.spanish==null?false:substate.editing.spanish.toString()};
    }

    return null;
  }
);

const selectEditingPatientPayment = createSelector(
  domainSelector,
  (substate) => {
    if (substate.editingActive === 'patientPayment') {
      return substate.editing;
    }

    return null;
  }
);

const editingSecuritySelector = createSelector(
  domainSelector,
  (substate) => {
    if (substate.editingActive === 'security') {
      return substate.editing;
    }

    return null;
  }
);

const editingPaymentPlansSelector = createSelector(
  domainSelector,
  (substate) => {
    if (substate.editingActive === 'paymentPlans') {
      return substate.editing;
    }

    return null;
  }
);

const editingElevateSelector = createSelector(
  domainSelector,
  (substate) => {
    if (substate.editingActive === 'elevate') {
      return substate.editing;
    }

    return null;
  }
);

const addLocationsSelector = createSelector(
  domainSelector,
  (substate) => {
    if (substate.editingActive === 'addLocation') {
      return substate.editing;
    }

    return null;
  }
);

const dentistRatingSelector = createSelector(
  domainSelector,
  substate => {
    if (substate.patients) {
      let iterations = 0;
      const rating = substate.patients.reduce((acc, p) => {
        return acc;
      }, 0);
      return Math.round(rating / iterations);
    }
    return 0;
  }
);

const selectRefundingMember = createSelector(
  domainSelector,
  (substate) => substate.refundingMember
);

const selectDisputeId = createSelector(
  domainSelector,
  (substate) => substate.disputeId
);

const selectDispute = createSelector(
  domainSelector,
  (substate) => substate.disputeData
);

const selectReloadingMember = createSelector(
  domainSelector,
  (substate) => substate.reloadingMember
);

const selectInitialLoad = createSelector(
  domainSelector,
  (substate) => substate.initialLoad
  );

const selectSearching= createSelector(
  domainSelector,
  (substate) => substate.isSearching
  );

const selectCheckedEmail = createSelector(
  domainSelector,
  (substate) => {
    return substate.checkedEmail; }
);

const selectLoadingMembers = createSelector(
  domainSelector,
  (substate) => {
    return substate.loadingMembers; }
);

const selectMemberTotal = createSelector(
  domainSelector,
  (substate) => {
    return substate.memberTotal; }
);

const selectNoMoreResults= createSelector(
  domainSelector,
  (substate) => {
    return substate.noMoreResults; }
);

const selectHasDisputes= createSelector(
  domainSelector,
  (substate) => {
    return substate.hasDisputes; }
);

const selectStripeRestricted= createSelector(
  domainSelector,
  (substate) => {
    return substate.stripeRestricted; }
);
/*
Export
------------------------------------------------------------
*/
export default domainSelector;

export {
  // search / filter patients
  selectMemberSearchTerm,
  selectMemberFilterTerm,
  selectDentistReports,
  selectDentistWebReport,
  selectDentistPayouts,
  selectDentistWebPayout,

  // fetch
  selectDentistInfo,
  selectStats,
  selectTotalRevenue,
  selectActiveMemberCount,
  selectMonthlyMembers,
  selectAnnualMembers,
  selectDentistUsers,
  selectPatients,
  selectOtherPatients,
  selectProcessedPatients,
  selectProcessedOtherPatients,
  selectDataLoaded,

  // add / edit
  selectEditingMember,
  selectEditingPatientProfile,
  selectEditingPatientPayment,
  editingSecuritySelector,
  editingPaymentPlansSelector,
  editingElevateSelector,
  addLocationsSelector,
  dentistRatingSelector,
  selectRefundingMember,
  selectDisputeId,
  selectDispute,
  selectReloadingMember,
  selectInitialLoad,
  selectSearching,
  selectPatientsIds,
  selectOtherPatientIds,
  selectCheckedEmail,
  selectEnvelopeId,
  selectLoadingMembers,
  selectMemberTotal,
  selectNoMoreResults,
  selectHasDisputes,
  selectStripeRestricted
};

