import get from 'lodash/get';
import { getItem } from 'utils/localStorage';



import {
  SET_AUTH_STATE,
  SET_USER_DATA,
  SERVICES_REQUEST_SUCCESS,
  SERVICES_REQUEST_ATTEMPT,
  CHANGE_PAGE_TITLE,
  REVIEW_ADDED,
  REVIEW_EDITED,

  FETCH_PLAID_LINK_TOKEN_SUCCESS,
  DENTIST_SPECIALTIES_SUCCESS,
  DENTIST_SPECIALTIES_ATTEMPT,
  PRICING_CODES_ATTEMPT,
  PRICING_CODES_SUCCESS,
  LEAD_TO_SF_SUCCESS,
  FETCH_ASSOCIATIONS_ATTEMPT,
  FETCH_ASSOCIATIONS_SUCCESS,
  ENVELOPE_ID,

  ADD_LOCATION_RESPONSE
} from './constants';

import {
  UPDATE_LAYOUT,
  TOGGLE_SIDENAV,
  SET_SIDENAV_OPEN,
  TOGGLE_SIDENAV_VARIANT,
  TOGGLE_SEARCHING,
  TOGGLE_NOTIFICATIONS,

} from './actions';


const initialState = {
  loggedIn: !!getItem('auth_token'),
  currentUser: false,
  services: [],
  fetchingServices: false,
  pageTitle: null,
  sidenavOpen: true,
  currentLayout: 'classic',
  notificationsOpen: true,
  sidenavVariant: 'temporary',
  isSearching: false,
  plaidLinkToken: null,
  dentistSpecialties: [],
  fetchingSpecialties: false,
  pricingCodes: [],
  fetchingPricingCodes: false,
  leadSubmitted: false,
  associations: [],
  fetchingAssociations: false,
  baaSubmit: null,
  addLocationRes: []
};

const portalData = JSON.parse(sessionStorage.getItem('portalData'));

const configuredLayout = {
  currentLayout: 'classic',
  notificationsOpen: false
};

const defaultLayout = portalData && portalData.layout ? portalData.layout : configuredLayout;

export default function appReducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {



    case SET_AUTH_STATE:
      return {
        ...state,
        loggedIn: payload.newAuthState,
      };

    case SET_USER_DATA:
      return {
        ...state,
        currentUser:
          (payload.currentUser === false)
            ? false
            : {
              ...payload.currentUser,
              phone: get(payload, 'currentUser.phoneNumbers[0].number'),
              address: get(payload, 'currentUser.addresses[0].value'),
            }
      };
    case SERVICES_REQUEST_ATTEMPT:
      return {
        ...state,
        fetchingServices: true
      };
    case SERVICES_REQUEST_SUCCESS:
      return {
        ...state,
        fetchingServices: false,
        services: payload.data.sort((serviceA, serviceB) => {
          const serviceAName = serviceA.name.toLowerCase();
          const serviceBName = serviceB.name.toLowerCase();

          if (serviceAName < serviceBName) {
            return -1;
          }
          else if (serviceAName > serviceBName) {
            return 1;
          }
          return 0; // serviceAName === serviceBName
        }),
      };

    case LEAD_TO_SF_SUCCESS:
      return {
        ...state,
        leadSubmitted: true,
      };

    case CHANGE_PAGE_TITLE:
      return {
        ...state,
        pageTitle: payload,
      };
    case REVIEW_ADDED:
    case REVIEW_EDITED:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          clientReviews: [payload]
        }
      };

    case TOGGLE_SIDENAV:
      return {
        ...state,
        sidenavOpen: !state.sidenavOpen
      };

    case SET_SIDENAV_OPEN:
      return {
        ...state,
        sidenavOpen: action.payload
      };

    case TOGGLE_SIDENAV_VARIANT:
      return {
        ...state,
        sidenavVariant: action.payload
      };

    case TOGGLE_NOTIFICATIONS:
      return {
        ...state,
        notificationsOpen: !state.notificationsOpen
      };

    case UPDATE_LAYOUT:
      return {
        ...state,
        currentLayout: action.payload
      };

    case TOGGLE_SEARCHING:
      return {
        ...state,
        isSearching: action.toggle
      };

    case FETCH_PLAID_LINK_TOKEN_SUCCESS:
      return {
        ...state,
        plaidLinkToken: payload.link_token
      };
    case DENTIST_SPECIALTIES_ATTEMPT:
      return {
        ...state,
        fetchingSpecialties: true
      };
    case DENTIST_SPECIALTIES_SUCCESS:
      return {
        ...state,
        fetchingSpecialties: false,
        dentistSpecialties: action.payload.sort((specialtyA, specialtyB) => {
          const specialtyAName = specialtyA.name.toLowerCase();
          const specialtyBName = specialtyB.name.toLowerCase();

          if (specialtyAName < specialtyBName) {
            return -1;
          }
          else if (specialtyAName > specialtyBName) {
            return 1;
          }
          return 0; // specialtyAName === specialtyBName
        }),
      };
    case PRICING_CODES_ATTEMPT:
      return {
        ...state,
        fetchingPricingCodes: true
      };
    case PRICING_CODES_SUCCESS:
      return {
        ...state,
        fetchingPricingCodes: false,
        pricingCodes: action.payload
          .map((priceCode) => {
            return {
              id: priceCode.id,
              code: priceCode.code
            };
          })
          .sort((priceCodeNameA, priceCodeNameB) => {
            if (priceCodeNameA < priceCodeNameB) {
              return -1;
            }
            else if (priceCodeNameA > priceCodeNameB) {
              return 1;
            }

            return 0;
          }),
      };
    case FETCH_ASSOCIATIONS_ATTEMPT:
      return {
        ...state,
        fetchingAssociations: true
      };

    case FETCH_ASSOCIATIONS_SUCCESS:
      return {
        ...state,
        associations: action.payload,
        fetchingAssociations: false
      };


    case ENVELOPE_ID:
      return {
        ...state,
        baaSubmit: action.which
      };
    case ADD_LOCATION_RESPONSE:
      return {
        ...state,
        addLocationRes: action.payload
      };

    default:
      return state;
  }
}


