import { NamedRoute } from 'new/routing/types';

export type OfficeSettingsRoutesName = 'OfficeSettings';

const OFFICE_SETTINGS_BASE_URL = '/account/settings/office';

export const PROFILE_INFO_SUB_URL = 'profile';
export const USERS_SUB_URL = 'users';
export const PMS_SUB_URL = 'pms';

export const officeSettingsRoutes: NamedRoute[] = [
  {
    name: 'OfficeSettings',
    path: `${OFFICE_SETTINGS_BASE_URL}`,
    getPath: (tab) => `${OFFICE_SETTINGS_BASE_URL}${tab ? `?tab=${tab}` : ''}`,
  },
];
