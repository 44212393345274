/*
Invite Form Modal Component
================================================================================
*/

/*
Import
------------------------------------------------------------
*/
// libs
import React from 'react';
import PropTypes from 'prop-types';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Modal from 'react-bootstrap/lib/Modal';
import Row from 'react-bootstrap/lib/Row';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import { Field, reduxForm, submit as submitForm } from 'redux-form';
import Checkbox from 'components/Checkbox';

// app
import LabeledInput from 'components/LabeledInput';
import Input from 'components/Input';

// local
import styles from './styles.module.css';
import InviteFormValidator from './validator';

/*
Redux
------------------------------------------------------------
*/
const mapDispatchToProps = (dispatch) => ({
  submit: () => dispatch(submitForm('invite')),
});

/*
Invite Form Modal
================================================================================
*/
@connect(null, mapDispatchToProps)
@reduxForm({
  form: 'invite',
  enableReinitialize: true,
  validate: InviteFormValidator,
})
@CSSModules(styles,{allowMultiple:true})
export default class InviteFormModal extends React.Component {

  static propTypes = {
    // form related - passed in
    initialValues: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,

    // modal related - passed in
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,

    // invite - passed in
    inviteTarget: PropTypes.string.isRequired,
  }

  getLabeledInput(props) {
    return new LabeledInput(props);
  }

  getCheckbox(props) {
    return new Checkbox(props);
  }

  getInput(props) {
    return new Input(props);
  }




  /*
  Render
  ------------------------------------------------------------
  */
  render() {
    const {
      // form related
      initialValues,
      handleSubmit,
      submit,
      submitting,

      // modal related
      show,
      onHide,

      // invite
      inviteTarget,
    } = this.props;

    let title = 'Invite';
    let label = 'Invitee\'s Email';
    if (inviteTarget === 'patient') {
      title = "Invite Patient";
      label = "Patient's Email";
    }
    else if (inviteTarget === 'dentist') {
      title = "Invite Dentist";
      label = "Dentist's Email";
    }

    return (
      <Modal
        backdrop={'static'}
        bsSize={'lg'}
        onHide={onHide}
        show={show}
      >
        {/*
        Modal Header
        ------------------------------------------------------------
        */}
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        {/*
        Modal Body
        ------------------------------------------------------------
        */}
        <Modal.Body>
          <form
            onSubmit={handleSubmit}
            className="form-horizontal"
            id="invite-form"
          >
            <Row>
             <div className="col-sm-6">
              <Field
                name="email"
                type="text"
                component={this.getLabeledInput}
                label={label}
                placeholder="jane@example.com"
              />
              </div>

              <div className="col-sm-6" styleName="spanishbox">
              <label className="col-sm-12"><b>Email Language</b></label>
               <div className="col-sm-3" styleName="language-radio">
                <Field name="spanish" component={this.getInput} type="radio" value="false" width={1}/>
                <div className="col-sm-6" styleName="languagepad">English</div>
               </div>
                <div className="col-sm-3" styleName="language-radio">
                  <Field name="spanish" component={this.getInput} type="radio" value="true" width={1}/>
                  <div className="col-sm-6" styleName="languagepad">Spanish</div>
                </div>
              </div>
            </Row>

          </form>
        </Modal.Body>

        {/*
        Modal Footer
        ------------------------------------------------------------
        */}
        <Modal.Footer>
          <div className="modal-controls">
            <input
              type="button"
              className="modal-control"
              disabled={submitting}
              onClick={submit}
              value="INVITE"
            />
          </div>
        </Modal.Footer>

      </Modal>
    );

  }
}
