import { api, apiNoResponseMsg } from 'new/api/axios';
import { Id } from 'new/api/types';
import { createEndpoint } from 'new/api/utils';
import {
  AddCardRequest,
  AddCardResponse,
  AddMemberRequest,
  AllPlanResponse,
  CancelPendingPlanRequest,
  CancelationInOfficeRequest,
  CancelationRequest,
  ChangeDrawDateRequest,
  ChangePlanRequest,
  ChargeMemberRequest,
  ChargeMemberResponse,
  ClaimGuaranteeRequest,
  DownloadTransactionsRequest,
  EditMemberInfoFormValues,
  EmailUsFormValues,
  HistoryResponse,
  InOfficePaymentRequest,
  LedgerRequest,
  LedgerResponse,
  LoggingNoteRequest,
  MemberDetailRequest,
  MemberDetailResponse,
  MembersDataRequest,
  MembersDataResponse,
  RefundMemberRequest,
  RefundMemberResponse,
  RefundTransactionRequest,
  RemoveChartIdRequest,
  RenewPlanRequest,
  RetryPaymentRequest,
  SendSecurePaymentUpdateLinkRequest,
  SetChartIdRequest,
  SwitchToInOfficeRequest,
  UpdateTermRequest,
} from 'new/modules/MembersPage/types';
import { filetypeHeaderMap } from '../Reports/endpoints';
import { InvitePatientFormValues } from './components/InvitePatientModal';
import { AutoRenewModalFormType } from './components/Modals/AutoRenewModal';
import {
  downloadFileFromResponse,
  getRequestConfigForFileDownload,
} from 'new/api/responseHandlers';

export const searchMembersData = createEndpoint<
  MembersDataResponse,
  MembersDataRequest
>({
  url: '/dentists/:id/members/upgraded-search',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const searchMembersFilter = createEndpoint<
  MembersDataResponse,
  MembersDataRequest
>({
  url: '/dentists/:id/members/upgraded-filters-search',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const getMemberDetail = createEndpoint<
  MemberDetailResponse,
  MemberDetailRequest
>({
  url: '/users/11/members/memberDetails', //TODO temp endpoint
  endpoint: ({ url, params }) => api.post(url, params),
});

export const editMemberInfo = createEndpoint<unknown, EditMemberInfoFormValues>(
  {
    url: '/users/:id/members/:id',
    endpoint: ({ url, params }) => api.put(url, params),
  },
);

export const emailUs = createEndpoint<unknown, EmailUsFormValues>({
  url: '/dentists/contact_account_support',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const invitePatient = createEndpoint<unknown, InvitePatientFormValues>({
  url: '/invite/upgrade-patient',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const autoRenew = createEndpoint<
  { _message: string },
  AutoRenewModalFormType & { autoRenew: boolean }
>({
  url: '/subscriptions/toggleAutoRenew',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const renewPlan = createEndpoint<{ _message: string }, RenewPlanRequest>(
  {
    url: '/subscriptions/members/:id/renew_cash',
    endpoint: ({ url, params }) => api.post(url, params),
  },
);

// export const cancelMember = (activeOfficeId: string) => createEndpoint<unknown, CancelationRequest>({
//   url: `/dentists/${activeOfficeId}/subscription/cancel_contract_plan`,
//   endpoint: ({ url, params }) => apiNoResponseMsg.post(url, params),
// });

export const cancelMember = createEndpoint<unknown, CancelationRequest>({
  url: '/dentists/:id/subscription/cancel_contract_plan',
  endpoint: ({ url, params }) => apiNoResponseMsg.post(url, params),
});

export const cancelMemberInOffice = createEndpoint<
  unknown,
  CancelationInOfficeRequest
>({
  url: '/dentists/:id/subscription/cancel_cash',
  endpoint: ({ url, params }) => apiNoResponseMsg.delete(url, { data: params }),
});

export const addNote = createEndpoint<unknown, LoggingNoteRequest>({
  url: '/users/me/account/loggingNote',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const getHistory = createEndpoint({
  url: '/users/me/account/getMemberHistory/:id',
  endpoint: ({ url }) => api.get<HistoryResponse>(url),
});

export const getLedgerEndpoint = ({
  userId,
  isSingleAccount,
  dentistInfoId,
}: LedgerRequest) =>
  api.post<LedgerResponse>(
    `/office/${dentistInfoId}/members/finances/${userId}/1970${
      isSingleAccount ? '/single' : ''
    }`,
  );

export const getAllPlans = createEndpoint({
  url: '/users/:id/dentist-info/all-plans',
  endpoint: ({ url, params }) => api.post<AllPlanResponse>(url, params),
});

export const changePlan = createEndpoint<unknown, ChangePlanRequest>({
  url: '/subscriptions/change_contract_plan',
  endpoint: ({ url, params }) => apiNoResponseMsg.put(url, params),
});

export const addCard = createEndpoint<
  AddCardResponse,
  Omit<AddCardRequest, 'id'>
>({
  url: '/users/:id/update-account/payment/sources',
  endpoint: ({ url, params }) => {
    return api.post<AddCardResponse>(url, params);
  },
});

export const cashPaymentSubscribe = createEndpoint({
  url: '/users/:id/account/payment/subscribe',
  endpoint: ({ url }) => api.post(url),
});

export const pastDue = createEndpoint({
  url: '/users/:id/account/payment/past_due',
  endpoint: ({ url }) => api.post(url),
});

export const chargeMember = createEndpoint<
  ChargeMemberResponse,
  ChargeMemberRequest
>({
  url: '/users/null/members/charge_user',
  endpoint: ({ url, params }) => api.post<ChargeMemberResponse>(url, params),
});

export const refundMember = createEndpoint<
  RefundMemberResponse,
  RefundMemberRequest
>({
  url: '/dentists/refunds',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const refundTransaction = createEndpoint<
  RefundMemberResponse,
  RefundTransactionRequest
>({
  url: '/dentists/refunds/single',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const addMembers = createEndpoint<unknown, AddMemberRequest>({
  url: `/users/:id/members/add`,
  endpoint: ({ url, params }) => api.post(url, params),
});

export const ledgerPrintReceipt = ({
  userId,
  chargeId,
}: {
  userId: Id;
  chargeId: Id;
  date: string;
}) =>
  api
    .get<Blob>(
      `/users/${userId}/receipts/${chargeId}`,
      getRequestConfigForFileDownload('pdf'),
    )
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: filetypeHeaderMap['pdf'],
        }),
      );

      window.open(url, '_blank');

      return response;
    });

export const cancelPendingPlan = createEndpoint<
  unknown,
  CancelPendingPlanRequest
>({
  url: '/subscriptions/change_contract_plan',
  endpoint: ({ url, params }) => api.delete(url, { data: params }),
});

export const clearDelinquency = createEndpoint<unknown, unknown>({
  url: '/users/me/members/clearDelinquency',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const retryPayment = createEndpoint<unknown, RetryPaymentRequest>({
  url: '/users/me/update-account/retryPayment',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const convertMember = createEndpoint<unknown, unknown>({
  url: 'subscriptions/pending_contract',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const setChartId = createEndpoint<unknown, SetChartIdRequest>({
  url: '/sikka-connect/setChartMatch',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const removeChartId = createEndpoint<unknown, RemoveChartIdRequest>({
  url: '/sikka-connect/removeChartMatch',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const claimGuarantee = createEndpoint<unknown, ClaimGuaranteeRequest>({
  url: '/subscriptions/claimGuarantee',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const inOfficePayment = createEndpoint<unknown, InOfficePaymentRequest>({
  url: '/subscriptions/in-office-payment',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const switchToInOffice = createEndpoint<
  unknown,
  SwitchToInOfficeRequest
>({
  url: '/subscriptions/to-cash',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const changeDrawDate = createEndpoint<unknown, ChangeDrawDateRequest>({
  url: '/subscriptions/plan-draw',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const sendSecurePaymentUpdateLink = createEndpoint<
  unknown,
  SendSecurePaymentUpdateLinkRequest
>({
  url: '/dentists/:id/members/sendSecurePaymentUpdateLink',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const downloadTransactions = ({
  memberId,
  isSingleAccount,
  dentistInfoId,
}: DownloadTransactionsRequest) => {
  const url = `/office/${dentistInfoId}/members/finances/reports?memberId=${memberId}&single=${isSingleAccount}`;
  return api
    .get<never>(url, getRequestConfigForFileDownload('xlsx'))
    .then((response) => {
      downloadFileFromResponse(response.data, 'xlsx');

      return response;
    });
};

export const updateTerm = createEndpoint<unknown, UpdateTermRequest>({
  url: `office/:id/members/subscriptions/updateTerm`,
  endpoint: ({ url, params }) => api.post(url, params),
});
