/*
Dentist Members Page Constants
================================================================================
*/

/* eslint-disable no-multi-spaces */

export const FETCH_DENTIST_INFO_REQUEST = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_INFO_REQUEST';
export const FETCH_DENTIST_INFO_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_INFO_SUCCESS';
export const FETCH_DENTIST_INFO_ERROR   = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_INFO_ERROR';

export const FETCH_DENTIST_INFO_FULL_REQUEST = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_INFO_FULL_REQUEST';
export const FETCH_DENTIST_INFO_FULL_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_INFO_FULL_SUCCESS';
export const FETCH_DENTIST_INFO_FULL_ERROR   = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_INFO_FULL_ERROR';

export const FETCH_STATS_REQUEST = 'app/dashboardUpdate/DentistMembersPage/FETCH_STATS_REQUEST';
export const FETCH_STATS_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/FETCH_STATS_SUCCESS';
export const FETCH_STATS_ERROR   = 'app/dashboardUpdate/DentistMembersPage/FETCH_STATS_ERROR';
export const FETCH_MEMBERSCSV_REQUEST = 'app/dashboardUpdate/DentistMembersPage/FETCH_MEMBERSCSV_REQUEST';

export const FETCH_PATIENTS_REQUEST = 'app/dashboardUpdate/DentistMembersPage/FETCH_PATIENTS_REQUEST';
export const FETCH_PATIENTS_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/FETCH_PATIENTS_SUCCESS';
export const FETCH_PATIENTS_ERROR   = 'app/dashboardUpdate/DentistMembersPage/FETCH_PATIENTS_ERROR';
export const FETCH_PATIENTS_ALL_LOCATIONS_REQUEST = 'app/dashboardUpdate/DentistMembersPage/FETCH_PATIENTS_ALL_LOCATIONS_REQUEST';
export const FETCH_PATIENTS_ALL_LOCATIONS_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/FETCH_PATIENTS_ALL_LOCATIONS_SUCCESS';

export const FETCH_DENTIST_USERS_REQUEST = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_USERS_REQUEST';
export const FETCH_DENTIST_USERS_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_USERS_SUCCESS';
export const FETCH_DENTIST_USERS_ERROR   = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_USERS_ERROR';

export const FETCH_RECURRING_DATE_REQUEST = 'app/dashboardUpdate/DentistMembersPage/FETCH_RECURRING_DATE_REQUEST';
export const FETCH_RECURRING_DATE_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/FETCH_RECURRING_DATE_SUCCESS';
export const FETCH_RECURRING_DATE_ERROR   = 'app/dashboardUpdate/DentistMembersPage/FETCH_RECURRING_DATE_ERROR';

export const FETCH_DENTIST_REPORTS_REQUEST = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_REPORTS_REQUEST';
export const FETCH_DENTIST_REPORTS_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_REPORTS_SUCCESS';
export const FETCH_DENTIST_REPORTS_ERROR   = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_REPORTS_ERROR';

export const FETCH_DENTIST_WEB_REPORT_REQUEST = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_WEB_REPORT_REQUEST';
export const FETCH_DENTIST_WEB_REPORT_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_WEB_REPORT_SUCCESS';
export const FETCH_DENTIST_WEB_REPORT_ERROR   = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_WEB_REPORT_ERROR';

export const FETCH_DENTIST_PAYOUTS_REQUEST = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_PAYOUTS_REQUEST';
export const FETCH_DENTIST_PAYOUTS_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_PAYOUTS_SUCCESS';
export const FETCH_DENTIST_PAYOUTS_ERROR   = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_PAYOUTS_ERROR';

export const FETCH_DENTIST_WEB_PAYOUT_REQUEST = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_WEB_PAYOUT_REQUEST';
export const FETCH_DENTIST_WEB_PAYOUT_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_WEB_PAYOUT_SUCCESS';
export const FETCH_DENTIST_WEB_PAYOUT_ERROR   = 'app/dashboardUpdate/DentistMembersPage/FETCH_DENTIST_WEB_PAYOUT_ERROR';

export const SEARCH_MEMBERS   = 'app/dashboardUpdate/DentistMembersPage/SEARCH_MEMBERS';
export const SEARCH_MEMBERS_FINISHED = 'app/dashboardUpdate/DentistMembersPage/SEARCH_MEMBERS_FINISHED';
export const FILTER_MEMBERS     = 'app/dashboardUpdate/DentistMembersPage/FILTER_MEMBERS';

export const SET_EDITING_MEMBER   = 'app/dashboardUpdate/DentistMembersPage/SET_EDITING_MEMBER';
export const CLEAR_EDITING_MEMBER = 'app/dashboardUpdate/DentistMembersPage/CLEAR_EDITING_MEMBER';
export const SUBMIT_MEMBER_FORM   = 'app/dashboardUpdate/DentistMembersPage/SUBMIT_MEMBER_FORM';
export const ADD_MEMBER_SUCCESS   = 'app/dashboardUpdate/DentistMembersPage/ADD_MEMBER_SUCCESS';
export const ACTIVATE_MEMBER_SUCCESS   = 'app/dashboardUpdate/DentistMembersPage/ACTIVATE_MEMBER_SUCCESS';
export const ACTIVATE_CASH_MEMBER_REQUEST   = 'app/dashboardUpdate/DentistMembersPage/ACTIVATE_CASH_MEMBER_REQUEST';
export const EDIT_MEMBER_SUCCESS  = 'app/dashboardUpdate/DentistMembersPage/EDIT_MEMBER_SUCCESS';
export const SWITCH_OFFICES   = 'app/dashboardUpdate/DentistMembersPage/SWITCH_OFFICES';

export const REMOVE_RELOADING_MEMBER ='app/dashboardUpdate/DentistMembersPage/REMOVE_RELOADING_MEMBER'
export const REMOVE_MEMBER_REQUEST = 'app/dashboardUpdate/DentistMembersPage/REMOVE_MEMBER_REQUEST';
export const REMOVE_MEMBER_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/REMOVE_MEMBER_SUCCESS';

export const SET_EDITING_PATIENT_PROFILE   = 'app/dashboardUpdate/DentistMembersPage/SET_EDITING_PATIENT_PROFILE';
export const CLEAR_EDITING_PATIENT_PROFILE = 'app/dashboardUpdate/DentistMembersPage/CLEAR_EDITING_PATIENT_PROFILE';
export const SUBMIT_PATIENT_PROFILE_FORM   = 'app/dashboardUpdate/DentistMembersPage/SUBMIT_PATIENT_PROFILE_FORM';
export const EDIT_PATIENT_PROFILE_SUCCESS  = 'app/dashboardUpdate/DentistMembersPage/EDIT_PATIENT_PROFILE_SUCCESS';

export const SET_EDITING_DENTIST_USER   = 'app/dashboardUpdate/DentistMembersPage/SET_EDITING_DENTIST_USER';
export const CLEAR_EDITING_DENTIST_USER = 'app/dashboardUpdate/DentistMembersPage/CLEAR_EDITING_DENTIST_USER';
export const SUBMIT_DENTIST_USER_FORM   = 'app/dashboardUpdate/DentistMembersPage/SUBMIT_DENTIST_USER_FORM';
export const EDIT_DENTIST_USER_SUCCESS  = 'app/dashboardUpdate/DentistMembersPage/EDIT_DENTIST_USER_SUCCESS';
export const DELETE_DENTIST_USER_FORM   = 'app/dashboardUpdate/DentistMembersPage/DELETE_DENTIST_USER_FORM';

export const SET_EDITING_PATIENT_PAYMENT   = 'app/dashboardUpdate/DentistMembersPage/SET_EDITING_PATIENT_PAYMENT';
export const CLEAR_EDITING_PATIENT_PAYMENT = 'app/dashboardUpdate/DentistMembersPage/CLEAR_EDITING_PATIENT_PAYMENT';
export const SUBMIT_PATIENT_PAYMENT_FORM   = 'app/dashboardUpdate/DentistMembersPage/SUBMIT_PATIENT_PAYMENT_FORM';
// don't store payment info locally

export const TOGGLE_WAIVE_PATIENT_FEES_REQUEST = 'app/dashboardUpdate/DentistMembersPage/TOGGLE_WAIVE_PATIENT_FEES_REQUEST';
export const TOGGLE_WAIVE_PATIENT_FEES_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/TOGGLE_WAIVE_PATIENT_FEES_SUCCESS';

export const DOWNLOAD_REPORT_REQUEST = 'app/dashboardUpdate/DentistMembersPage/DOWNLOAD_REPORT_REQUEST';
export const DOWNLOAD_REPORT_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/DOWNLOAD_REPORT_SUCCESS';
export const DOWNLOAD_REPORT_ERROR   = 'app/dashboardUpdate/DentistMembersPage/DOWNLOAD_REPORT_ERROR';

export const UPLOAD_IMAGE_REQUEST = 'app/dashboardUpdate/DentistMembersPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS   = 'app/dashboardUpdate/DentistMembersPage/UPLOAD_IMAGE_SUCCESS';

export const DENTIST_SIGNUP_REQUEST      = 'app/dashboardUpdate/DentistMembersPage/DENTIST_SIGNUP_REQUEST';
export const DENTIST_SIGNUP_SUCCESS      = 'app/dashboardUpdate/DentistMembersPage/DENTIST_SIGNUP_SUCCESS';
export const DENTIST_SIGNUP_ERROR        = 'app/dashboardUpdate/DentistMembersPage/DENTIST_SIGNUP_ERROR';

// edit security settings
export const SET_EDITING_SECURITY = 'app/dashboardUpdate/DentistMembersPage/SET_EDITING_SECURITY';
export const CLEAR_EDITING_SECURITY = 'app/dashboardUpdate/DentistMembersPage/CLEAR_EDITING_SECURITY';
export const SUBMIT_SECURITY_FORM = 'app/dashboardUpdate/DentistMembersPage/SUBMIT_SECURITY_FORM';

// edit PAYMENT_PLANS settings
export const SET_EDITING_PAYMENT_PLANS = 'app/dashboardUpdate/DentistMembersPage/SET_EDITING_PAYMENT_PLANS';
export const CLEAR_EDITING_PAYMENT_PLANS = 'app/dashboardUpdate/DentistMembersPage/CLEAR_EDITING_PAYMENT_PLANS';
export const SUBMIT_PAYMENT_PLANS_FORM = 'app/dashboardUpdate/DentistMembersPage/SUBMIT_PAYMENT_PLANS_FORM';

export const SET_ELEVATE = 'app/dashboardUpdate/DentistMembersPage/SET_ELEVATE';
export const CLEAR_ELEVATE = 'app/dashboardUpdate/DentistMembersPage/CLEAR_ELEVATE';
// images
export const SET_OFFICE_LOGO = 'app/dashboardUpdate/DentistMembersPage/SET_OFFICE_LOGO';
export const SET_PRINT_LOGO = 'app/dashboardUpdate/DentistMembersPage/SET_PRINT_LOGO';
export const SET_DENTIST_AVATAR = 'app/dashboardUpdate/DentistMembersPage/SET_DENTIST_AVATAR';
export const SET_DENTIST_OFFICE_IMAGE = 'app/dashboardUpdate/DentistMembersPage/SET_DENTIST_OFFICE_IMAGE';
export const DELETE_OFFICE_LOGO = 'app/dashboardUpdate/DentistMembersPage/DELETE_OFFICE_LOGO';
export const DELETE_PRINT_LOGO = 'app/dashboardUpdate/DentistMembersPage/DELETE_PRINT_LOGO';
export const DELETE_DENTIST_AVATAR = 'app/dashboardUpdate/DentistMembersPage/DELETE_DENTIST_AVATAR';
export const DELETE_DENTIST_OFFICE_IMAGE = 'app/dashboardUpdate/DentistMembersPage/DELETE_DENTIST_OFFICE_IMAGE';
export const DELETE_OFFICE_LOGO_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/DELETE_OFFICE_LOGO_SUCCESS';
export const DELETE_DENTIST_AVATAR_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/DELETE_DENTIST_AVATAR_SUCCESS';
export const DELETE_DENTIST_OFFICE_IMAGE_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/DELETE_DENTIST_AVATAR_SUCCESS';

export const SET_OFFICE_LOGO_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/SET_OFFICE_LOGO_SUCCESS';
// update user data at App level, see SET_USER_DATA in `/app/containers/App/constants.js`

// custom membership plan constants
export const ADD_CUSTOM_MEMBERSHIP = 'app/dashboardUpdate/DentistMembersPage/ADD_CUSTOM_MEMBERSHIP';
export const EDIT_CUSTOM_MEMBERSHIP = 'app/dashboardUpdate/DentistMembersPage/EDIT_CUSTOM_MEMBERSHIP';
export const DELETE_CUSTOM_MEMBERSHIP = 'app/dashboardUpdate/DentistMembersPage/DELETE_CUSTOM_MEMBERSHIP';

export const INVITE_PATIENT = 'app/dashboardUpdate/DentistMembersPage/INVITE_PATIENT';
export const ADD_VARIDI= 'app/dashboardUpdate/DentistMembersPage/ADD_VARIDI';
export const GARGLE_VARIDI= 'app/dashboardUpdate/DentistMembersPage/GARGLE_VARIDI';


export const FETCH_OFFICES = 'app/dashboardUpdate/DentistMembersPage/FETCH_OFFICES';
export const FETCH_OFFICES_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/FETCH_OFFICES_SUCCESS';
export const FETCH_OFFICES_ERROR = 'app/dashboardUpdate/DentistMembersPage/FETCH_OFFICES_ERROR';

export const TOGGLE_REFUNDING_MEMBER = 'app/dashboardUpdate/DentistMembersPage/TOGGLE_REFUNDING_MEMBER';
export const INITIATE_REFUNDING_MEMBER = 'app/dashboardUpdate/DentistMembersPage/INITIATE_REFUNDING_MEMBER';
export const REFUNDING_MEMBER_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/REFUNDING_MEMBER_SUCCESS';
export const FAILED_REFUNDING_MEMBER = 'app/dashboardUpdate/DentistMembersPage/FAILED_REFUNDING_MEMBER';

export const TOGGLE_DISPUTES_MODAL = 'app/dashboardUpdate/DentistMembersPage/TOGGLE_DISPUTES_MODAL';
export const TOGGLE_DISPUTES_CONFIRM_MODAL = 'app/dashboardUpdate/DentistMembersPage/TOGGLE_DISPUTES_CONFIRM_MODAL';
export const INITIATE_DISPUTE_UPDATE = 'app/dashboardUpdate/DentistMembersPage/INITIATE_DISPUTE_UPDATE';
export const INITIATE_DISPUTE_ACCEPT = 'app/dashboardUpdate/DentistMembersPage/INITIATE_DISPUTE_ACCEPT';
export const DISPUTE_RESPONSE_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/DISPUTE_RESPONSE_SUCCESS';
export const DISPUTE_RESPONSE_FAILURE = 'app/dashboardUpdate/DentistMembersPage/DISPUTE_RESPONSE_FAILURE';
export const FETCH_DISPUTE_ID = 'app/dashboardUpdate/DentistMembersPage/FETCH_DISPUTE_ID';
export const FETCH_DISPUTE_ID_SUCCESS = 'app/dashboardUpdate/DentistMembersPage/FETCH_DISPUTE_ID_SUCCESS';
export const FETCH_DISPUTE_ID_FAILURE = 'app/dashboardUpdate/DentistMembersPage/FETCH_DISPUTE_ID_FAILURE';
export const CLEAR_DISPUTE_RESPONSE = 'app/dashboardUpdate/DentistMembersPage/CLEAR_DISPUTE_RESPONSE';
export const CLEAR_DISPUTE_ACCEPT = 'app/dashboardUpdate/DentistMembersPage/CLEAR_DISPUTE_ACCEPT';
export const FETCH_BY_DISPUTE_ID= 'app/dashboardUpdate/DentistMembersPage/FETCH_BY_DISPUTE_ID';

export const TOGGLE_SAW_UPDATES_REQUEST = 'app/dashboardUpdate/DentistMembersPage/TOGGLE_SAW_UPDATES_REQUEST';
export const SET_ADD_LOCATION = 'app/dashboardUpdate/DentistMembersPage/SET_ADD_LOCATION';
export const CLEAR_ADD_LOCATION = 'app/dashboardUpdate/DentistMembersPage/CLEAR_ADD_LOCATION';

export const DENTIST_CODES_SIGNUP      = 'app/dashboardUpdate/DentistMembersPage/DENTIST_CODES_SIGNUP';
export const DENTIST_DISCOUNT_SIGNUP      = 'app/dashboardUpdate/DentistMembersPage/DENTIST_DISCOUNT_SIGNUP';
export const DENTIST_DISCOUNT_CODES_SIGNUP      = 'app/dashboardUpdate/DentistMembersPage/DENTIST_DISCOUNT_CODES_SIGNUP';
export const SWITCH_TO_STEP = 'app/dashboardUpdate/DentistMembersPage/SWITCH_TO_STEP';
export const REMOVE_STANDARD ='app/dashboardUpdate/DentistMembersPage/REMOVE_STANDARD'


export const ACTIVE_CAMPAIGN_COMPLETE= 'app/dashboardUpdate/DentistMembersPage/ACTIVE_CAMPAIGN_COMPLETE';

export const  ADD_DENTIST_PRICE_CODES= 'app/dashboardUpdate/DentistMembersPage/ADD_DENTIST_PRICE_CODES';

export const SUBMIT_CONTACT_US_MESSAGE_FORM='app/dashboardUpdate/DentistMembersPage/SUBMIT_CONTACT_US_MESSAGE_FORM';
export const SUBMIT_CONTACT_US_ACCOUNT_FORM='app/dashboardUpdate/DentistMembersPage/SUBMIT_CONTACT_US_ACCOUNT_FORM';
export const CHARGE_FOR_MARKETING_MATERIALS='app/dashboardUpdate/DentistMembersPage/CHARGE_FOR_MARKETING_MATERIALS';
export const SEND_MM='app/dashboardUpdate/DentistMembersPage/SEND_MM'

export const REACTIVATE_USER='app/dashboardUpdate/DentistMembersPage/REACTIVATE_USER';
export const RESEND_PASSWORD_EMAIL='app/dashboardUpdate/DentistMembersPage/RESEND_PASSWORD_EMAIL'
export const   FETCH_DEFAULT_PAYMENT_REQUEST='app/dashboardUpdate/DentistMembersPage/FETCH_DEFAULT_PAYMENT_REQUEST'
export const   FETCH_DEFAULT_PAYMENT_SUCCESS='app/dashboardUpdate/DentistMembersPage/FETCH_DEFAULT_PAYMENT_SUCCESS'

export const RENEW_MEMBER_REQUEST='app/dashboardUpdate/DentistMembersPage/RENEW_MEMBER_REQUEST'

export const UPDATE_BUSINESS_CONNECT_TRAINING_ON='app/dashboardUpdate/DentistMembersPage/UPDATE_BUSINESS_CONNECT_TRAINING_ON'

export const CHECK_EMAIL= 'app/dashboardUpdate/SignupPatientRegformPage/CHECK_EMAIL';
export const CHECK_EMAIL_ERROR= 'app/dashboardUpdate/SignupPatientRegformPage/CHECK_EMAIL_ERROR';
export const CHECK_EMAIL_SUCCESS= 'app/dashboardUpdate/SignupPatientRegformPage/CHECK_EMAIL_SUCCESS';

export const CLEAR_SEARCH_PATIENTS = 'app/dashboardUpdate/DentistMembersPage/CLEAR_SEARCH_PATIENTS'

