import React from 'react';
import ErrorModalContent, { ErrorConfigProps } from './ErrorModalContent';
import SuccessModalContent, { SuccessConfigProps } from './SuccessModalContent';

type Props = {
  isSuccess: boolean;
  isError: boolean;
  successConfig?: SuccessConfigProps;
  errorConfig?: ErrorConfigProps;
};

const ModalFinalView: React.FC<Props> = ({
  successConfig,
  errorConfig,
  isSuccess,
  isError,
}) => {
  return isSuccess && successConfig ? (
    <SuccessModalContent {...successConfig} />
  ) : isError && errorConfig ? (
    <ErrorModalContent {...errorConfig} />
  ) : null;
};

export default ModalFinalView;
