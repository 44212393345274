/*
Invite Form Validator
================================================================================
*/

/*
Imports
------------------------------------------------------------
*/
// app
import { validatorFactory } from 'utils/reduxForm';

/*
Schema
------------------------------------------------------------
*/
const schema = {
  email: {
    presence: true,
    email: true,
  }
};

export default validatorFactory(schema);