import { styled, Box, Typography } from '@mui/material';

export const ModalContainer = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'space-between',
  minWidth: 450,
});

export const ModalContent = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 20,
});

export const ModalTitle = styled(Typography)({
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '1.5rem',
});

export const Title = styled(Typography)({
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '2rem',
});

export const Subtitle = styled(Typography)({
  position: 'relative',
  top: -16,
  fontWeight: 'bold',
  fontSize: '1.125rem',
});

export const ErrorTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '1.75rem',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  marginTop: 0,
  textAlign: 'center',
}));

export const MainMessageText = styled(Typography)({
  fontSize: '1.75rem',
  fontWeight: 'bold',
  lineHeight: 1.2,
  textAlign: 'center',
});

export const SecondaryMessageText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  textAlign: 'center',
}));

export const PriceMessage = styled(Typography)(({ theme }) => ({
  fontSize: '3rem',
  fontWeight: 300,
  lineHeight: 1
}));

export const SuccessMessage = styled(Typography)(({ theme }) => ({
    color: theme.palette.success.main,
    fontSize: '1.5rem',
    fontWeight: 'bold'
}));

export const AdditionalButtonText = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  fontWeight: 300,
  fontSize: '0.75rem',
  color: theme.palette.grey[600],
  position: 'absolute',
  top: 110,
}));

export const UnderButtonText = styled(Typography)(({ theme }) => ({
  position: 'relative',
  top: 16,
  fontStyle: 'italic',
  fontWeight: 300,
  fontSize: '0.875rem',
  color: theme.palette.grey[600],
}));

export const ModalFooter = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(4),
}));