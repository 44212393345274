import { NamedRoute } from '../../routing/types';

export type CheckoutRouteName = 'CheckoutPage';

export const checkoutRoutes: NamedRoute[] = [
  {
    name: 'CheckoutPage',
    path: '/subscribe',
    permission: '_all',
  },
];
