import { NamedRoute } from 'new/routing/types';
import { TENANT } from 'new/tenants';

const EXTERNAL_PATIENT_BASE_URL = '/external-patient-signup';
const EXTERNAL_PATIENT_LANDING_BASE_URL =
  TENANT.constants.externalPatientLandingBaseUrl;

export type ExternalPatientRouteName = 'ExternalPatientSignup';
export type ExternalPatientLandingRouteName = 'ExternalPatientLandingPage';

export const externalPatientRoutes: NamedRoute[] = [
  {
    name: 'ExternalPatientSignup',
    path: EXTERNAL_PATIENT_BASE_URL,
    getPath: (tab) => `${EXTERNAL_PATIENT_BASE_URL}${tab ? `?tab=${tab}` : ''}`,
  },
  {
    name: 'ExternalPatientLandingPage',
    path: EXTERNAL_PATIENT_LANDING_BASE_URL,
    getPath: (dentistInfoId) =>
      `${EXTERNAL_PATIENT_LANDING_BASE_URL}/${dentistInfoId}`,
  },
  {
    name: 'ExternalPatientLandingPage',
    path: EXTERNAL_PATIENT_LANDING_BASE_URL,
    getPath: (dentistInfoId) =>
      `${EXTERNAL_PATIENT_LANDING_BASE_URL}/${dentistInfoId}`,
  },
];
