import { NamedRoute } from '../../routing/types';

export type MembershipRouteName =
  | 'MembershipStandardOnlyPage'
  | 'EmbeddedMembershipPage';

export const membershipRoutes: NamedRoute[] = [
  {
    name: 'MembershipStandardOnlyPage',
    path: '/enroll/practice',
    permission: '_all',
  },
  {
    name: 'EmbeddedMembershipPage',
    path: '/enroll/embed',
    permission: '_all',
  },
];
