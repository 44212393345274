import React, { PropsWithChildren, useEffect, useState } from 'react';
import { history } from 'app';

import { defaultLocale, locales } from './i18nConfig';
import { LocaleContext } from './LocaleContext';
import { IntlProvider } from 'react-intl';
import { Locale } from './types';
import { TENANT } from 'new/tenants';

const LOCALIZATION_ENABLED_PAGES = [
  'external-patient-signup',
  `accounts/signup/my-${TENANT.professionalName}`,
];

const I18n = ({ children }: PropsWithChildren<{}>) => {
  const [locale, setLocale] = useState<Locale>(defaultLocale);
  const [pagePath, setPagePath] = useState(
    history.getCurrentLocation().pathname,
  );

  useEffect(() => {
    const unListen = history.listen(() => {
      setPagePath(history.getCurrentLocation().pathname);
    });
    return () => {
      unListen();
    };
  }, []);

  const isActive = LOCALIZATION_ENABLED_PAGES.some((page) =>
    pagePath.includes(page),
  );

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <IntlProvider
        locale={isActive ? locale : defaultLocale}
        defaultLocale={defaultLocale}
        messages={locales[TENANT.id][locale].messages}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default I18n;
