import { NamedRoute } from '../../routing/types';

export type InternalPatientRouteName = 'InternalPatientSignup';

const INTERNAL_PATIENT_BASE_URL = '/account/patient-signup';

export const MEMBER_INFO_SUB_URL = 'member-info';
export const CONFIRM_SUB_URL = 'confirm';
export const CHECKOUT_SUB_URL = 'checkout';
export const VIEW_PLANS_SUB_URL = 'view-plans';

export const internalPatientRoutes: NamedRoute[] = [
  {
    name: 'InternalPatientSignup',
    path: INTERNAL_PATIENT_BASE_URL,
    getPath: (tab) => `${INTERNAL_PATIENT_BASE_URL}${tab ? `?tab=${tab}` : ''}`,
  },
];
