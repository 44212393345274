import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { ResponseWithMessage } from './types';

const getStatusFallbackMessage = (status?: number) => {
  const errors: {
    [key: number]: string;
  } = {
    400: `Bad Request`,
    401: `Unauthorized`,
    403: `Access Denied`,
    404: `Not Found`,
    500: `Internal Server Error`,
    504: `Server Timeout`,
  };

  return status ? errors[status] : errors[500];
};

const getErrorMessage = async (error: AxiosError<ResponseWithMessage>) => {
  const { response } = error;

  if (response?.data?._message) {
    return response.data._message;
  }

  if (typeof response?.data.errors === 'string') {
    return response.data.errors;
  }

  if (response?.data instanceof Blob) {
    // response data come as blob if responseType is blob (file download)
    const text = JSON.parse(await response.data.text());
    if (text._message) {
      return text._message;
    }
  }

  return getStatusFallbackMessage(error.response?.status || 500);
};

export const errorHandler = async (error: AxiosError<ResponseWithMessage>) => {
  if (axios.isCancel(error)) return Promise.reject(error);

  const errorMessage = await getErrorMessage(error);

  toast(errorMessage, { type: 'error', toastId: errorMessage });

  return Promise.reject(error);
};

export const errorHandlerNoToast = (error: AxiosError<ResponseWithMessage>) => {
  if (axios.isCancel(error)) return Promise.reject(error);
  return Promise.reject(error);
};
