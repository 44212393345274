import { createEndpoint } from 'new/api/utils';
import { api } from 'new/api/axios';
import {
  AssignUserRequest,
  ManageUser,
  ManageUserAssignments,
  RemoveUserAssignmentRequest,
} from 'new/modules/ManageUsers/types';
import { ChangeUserSettingsRequest } from 'new/modules/UserSettings/types';

export const geUsersList = createEndpoint<ManageUser[], unknown>({
  url: '/multi-location/manage-users/:id',
  endpoint: ({ url }) => api.get<ManageUser[]>(url),
});

export const geUsersAssignments = createEndpoint<
  ManageUserAssignments,
  unknown
>({
  url: '/multi-location/manage-user-assignments/:id',
  endpoint: ({ url }) => api.get<ManageUserAssignments>(url),
});

export const assignUsers = createEndpoint<unknown, AssignUserRequest>({
  url: '/multi-location/assign-users/:id',
  endpoint: ({ url, params }) => api.post<ManageUserAssignments>(url, params),
});

export const removeAssignment = createEndpoint<
  unknown,
  RemoveUserAssignmentRequest
>({
  url: '/multi-location/assign-users/:id',
  endpoint: ({ url, params }) =>
    api.delete<RemoveUserAssignmentRequest>(url, { data: params }),
});

export const changeUserSettingsNew = createEndpoint<
  unknown,
  ChangeUserSettingsRequest
>({
  url: '/users/me/account/change-manage-user-setting',
  endpoint: ({ url, params }) => api.put(url, params),
});
