import { NamedRoute } from 'new/routing/types';

export type InhouseFinancingRouteName = 'InhouseFinancing';

export const inhouseFinancingRoutes: NamedRoute[] = [
  {
    name: 'InhouseFinancing',
    path: `/account/inhouse-financing`,
  },
];
