import { useMutation, useQuery } from 'react-query';
import create, { GetState, SetState } from 'zustand';
import { persist, StoreApiWithPersist } from 'zustand/middleware';
import {
  ManageLocationsStore,
  ManageLocationTab,
  RemoveLocationFromGroupRequest,
} from 'new/modules/ManageLocations/types';
import {
  addLocationToGroup,
  getLocations,
  getLocationDetails,
  removeLocationFromGroup,
} from './endpoints';

export const useManageLocationsTabsStore = create<
  ManageLocationsStore,
  SetState<ManageLocationsStore>,
  GetState<ManageLocationsStore>,
  StoreApiWithPersist<ManageLocationsStore>
>(
  persist(
    (set) => ({
      manageLocations: [] as ManageLocationTab[],
      setManageLocationsTabs: (manageLocations) => set({ manageLocations }),
      resetManageLocationsTabs: () => set({ manageLocations: [] }),
    }),
    {
      name: 'manageLocationsTabs',
      version: 1,
      getStorage: () => sessionStorage,
    },
  ),
);

export const useGetLocations = (organizationId?: number, userId?: number) =>
  useQuery({
    queryKey: ['MANAGE_LOCATIONS', userId, organizationId],
    queryFn: () =>
      getLocations
        .endpoint({ organizationId: organizationId as number }, userId)
        .then((res) => res.data),
    enabled: !!userId && !!organizationId,
  });

export const useAddLocationsToGroup = () =>
  useMutation(addLocationToGroup.endpoint);

export const useGetLocationDetails = (locationId?: string) =>
  useQuery({
    queryKey: `MANAGE LOCATION DETAILS: ${locationId}`,
    queryFn: () =>
      getLocationDetails.endpoint({}, locationId).then((res) => res.data),
    enabled: !!locationId,
  });

export const useAddGroup = () =>
  useMutation<
    { name: string },
    unknown,
    { groupName: string; organizationId: number }
  >((params) =>
    addLocationToGroup
      .endpoint({
        groupName: params.groupName,
        locationsIds: [],
        organizationId: params.organizationId,
      })
      .then((res) => res.data),
  );

export const useRemoveLocationsFromGroup = () =>
  useMutation<unknown, unknown, RemoveLocationFromGroupRequest>((params) =>
    removeLocationFromGroup
      .endpoint({
        groupId: params.groupId,
        locationIds: params.locationIds,
      })
      .then((res) => res.data),
  );
